import { Mark } from 'tiptap';
import { updateMark } from 'tiptap-commands';

import { COLOR_MARK_NAME } from '@/use/text-editor/constants';

export default class ColorMark extends Mark {
	get name() {
		return COLOR_MARK_NAME;
	}

	get schema() {
		return {
			attrs: { color: { default: '' } },
			parseDOM: [
				{
					style: 'color',
					getAttrs: (color) => ({ color }),
				},
			],
			toDOM: ({ attrs: { color } }) => [
				'span',
				{ style: `color: ${color}` },
				0,
			],
		};
	}

	commands({ type }) {
		return (attributes) => updateMark(type, attributes);
	}
}
