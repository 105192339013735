import { Node } from 'tiptap';
import {
	toggleBlockType,
	textblockTypeInputRule,
} from 'tiptap-commands';

import {
	TEXT_TYPES,
	H3_TAG,
	H3_CLASS,
	HEADING_NODE_NAME,
} from '@/use/text-editor/constants';
import { sanitizeTagStyles } from '@/use/text-editor/utils';
/**
 * ProseMirror (library on which TipTap is based) is very strict. Telling him that we need to match
 * 'h-1` class on any tag is not enough - we need to provide both the tag and the class to match on.
 * Below, combinations of all matches are generated (h1.h-1, h1.h-2, ... h5.h-1, h5.h-2)
 */
const DEFAULT_LEVEL = 3;
const defaultHeadings = [
	1,
	2,
	3,
	4,
	5,
	6,
].map((level) => ({
	level,
	tag: `h${level}`,
	defaultClassName: `h-${level}`,
}));
const headings = Object.keys(TEXT_TYPES).reduce(
	(accumulator, className) => [
		...accumulator,
		...defaultHeadings.map((heading) => ({
			...heading,
			className,
		})),
	],
	[],
);

export default class Heading extends Node {
	get name() {
		return HEADING_NODE_NAME;
	}

	get schema() {
		return {
			attrs: {
				className: { default: H3_CLASS },
				style: { default: null },
				level: { default: DEFAULT_LEVEL },
				tag: { default: H3_TAG },
			},
			content: 'inline*',
			group: 'block',
			defining: true,
			draggable: false,
			parseDOM: [
				...headings.map(({
					tag,
					className,
				}) => ({
					tag: `${tag}.${className}`,
					getAttrs: (dom) => ({
						className,
						style: dom.getAttribute('style'),
						tag,
					}),
				})),
				// Handle tags without classes as well
				...headings.map(({
					tag,
					defaultClassName,
				}) => ({
					tag,
					getAttrs: (dom) => ({
						className: defaultClassName,
						style: dom.getAttribute('style'),
						tag,
					}),
				})),
			],
			toDOM: ({
				attrs: {
					className,
					style,
					tag,
				},
			}) => [
				tag,
				{
					class: className,
					style: sanitizeTagStyles(style),
				},
				0,
			],
		};
	}

	commands({
		type,
		schema,
	}) {
		return (attributes) => toggleBlockType(type, schema.nodes.paragraph, attributes);
	}

	inputRules({ type }) {
		return headings.map(({
			level,
			tag,
		}) => textblockTypeInputRule(new RegExp('^(#{1,'.concat(level, '})\\s$')), type, () => ({ tag })));
	}
}
