<template>
	<div class="input">
		<ZyroButton
			v-if="importedContentUrl"
			v-qa="'websiteimporter-btn-back'"
			class="input__close"
			icon="arrow-right"
			:title="$t('common.goBack')"
			@click="$emit('go-back')"
		/>
		<div class="icon">
			<ZyroSvg
				class="icon__1"
				name="website-importer-blob1"
			/>
			<ZyroSvg
				class="icon__2"
				name="website-importer-blob2"
			/>
			<div class="icon__loader-container">
				<ZyroSvg
					v-if="!loading"
					class="icon__3"
					name="website-importer"
				/>
				<ZyroLoader
					v-if="loading"
					class="icon__loader"
					style="--color: white; --border-color: #583bb6; --size: 22px;"
				/>
			</div>
		</div>
		<template v-if="loading">
			<p class="loading-url z-body-small z-body-small--strong">
				{{ url }}
			</p>
			<p class="loading-text z-h5">
				{{ $t('builder.websiteImporter.loadingContent') }}
			</p>
			<ZyroButton
				v-qa="'websiteimporter-btn-cancel'"
				theme="outline"
				@click="$emit('cancel-scan-website')"
			>
				{{ $t('common.cancel') }}
			</ZyroButton>
		</template>
		<template v-else-if="showWelcomeView">
			<h5 class="welcome__subtitle z-subheading">
				{{ $t('builder.websiteImporter.welcome.subtitle') }}
			</h5>
			<h2 class="welcome__title z-h5">
				{{ $t('builder.websiteImporter.title') }}
			</h2>
			<p class="welcome__description z-body-small">
				{{ $t('builder.websiteImporter.welcome.text') }}
			</p>
			<ZyroButton
				theme="primary"
				data-qa="builder-websiteimporter-btn-importimagesandtext"
				@click="showWelcomeView = false"
			>
				{{ $t('builder.websiteImporter.welcome.btn') }}
			</ZyroButton>
		</template>
		<template v-else>
			<h3 class="input__title z-h5">
				{{ $t('builder.websiteImporter.input.title') }}
			</h3>
			<ZyroFieldText
				v-qa="'websiteimporter-input-url'"
				:value="url"
				:label="$t('builder.websiteImporter.input.label')"
				class="input__input"
				:error="errorMessage"
				:autofocus="true"
				@input="url = $event.target.value, showValidationError = false, $emit('input')"
				@keydown.enter="submit"
			/>
			<!-- Temporary don't show subpage select option -->
			<!-- <zyro-field-toggle
				id="subpages"
				name="subpages"
				:label="$t('builder.websiteImporter.input.toggle.label')"
				class="input__toggle"
				:message="includeSubpages
					? $t('builder.websiteImporter.input.toggle.message')
					: ''"
				:checked="includeSubpages"
				@change="includeSubpages = !includeSubpages"
			/> -->
			<ZyroSeparator class="input__separator" />
			<div class="input__button-container">
				<ZyroButton
					v-qa="isAlreadyImported ? 'websiteimporter-btn-goback' : 'websiteimporter-btn-scan'"
					:theme="isAlreadyImported ? 'outline' : 'primary'"
					class="input__button"
					:disabled="!url || showValidationError"
					@click="submit"
				>
					{{ isAlreadyImported
						? $t('common.goBack'): $t('builder.websiteImporter.input.btn') }}
				</ZyroButton>
			</div>
			<p
				v-if="validUrl.isUrlValid"
				class="input__legal-info z-body-small"
			>
				{{ $t('builder.websiteImporter.input.legal') }}
			</p>
		</template>
		<div class="background-shapes">
			<ZyroSvg
				class="background-shapes__left"
				name="website-importer-shape1"
			/>
			<ZyroSvg
				class="background-shapes__right"
				name="website-importer-shape2"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import { isDevelopment } from '@/utils/flags';
import { getValidUrl } from '@/utils/urlValidators';

export default {
	isDevelopment,
	props: {
		importedContentUrl: {
			type: String,
			required: true,
		},
		importedWithSubpages: {
			type: Boolean,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showWelcomeView: true,
			url: '',
			includeSubpages: false,
			showValidationError: false,
		};
	},
	computed: {
		...mapGetters('user', ['isZyroUser']),
		validUrl() {
			return getValidUrl(this.url, true);
		},
		errorMessage() {
			let error = '';

			if (this.showValidationError && !this.validUrl.isUrlValid) {
				error = this.$t('builder.websiteImporter.input.error');
			}

			return error;
		},
		isAlreadyImported() {
			if (!this.importedContentUrl) {
				return false;
			}

			return this.importedContentUrl === this.validUrl.url
			&& this.importedWithSubpages === this.includeSubpages;
		},
	},
	methods: {
		submit() {
			if (!this.validUrl.isUrlValid) {
				this.showValidationError = true;

				return;
			}

			if (this.isAlreadyImported) {
				this.$emit('go-back');

				return;
			}

			this.$emit('scan-website', {
				url: this.validUrl.url,
				includeSubpages: this.includeSubpages,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.input {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;

	&__title {
		margin-bottom: 48px;
		color: $grey-800;
		text-align: center;
	}

	&__input {
		width: 100%;
		margin-bottom: 16px;
	}

	&__button {
		margin-bottom: 32px;
	}

	&__separator {
		margin-bottom: 32px;
	}

	&__legal-info {
		color: $grey-800;
		text-align: center;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}

.welcome {
	&__subtitle {
		color: $grey-800;
	}

	&__title {
		margin-bottom: 16px;
	}

	&__description {
		margin-bottom: 72px;
		color: $grey-800;
		text-align: center;
	}
}

.icon {
	position: relative;
	width: 112px;
	height: 116px;

	&__1 {
		position: absolute;
		top: 0;
		left: 0;
		animation: pulse 2.5s 0.3s infinite ease;
	}

	&__2 {
		position: absolute;
		top: 15px;
		left: 13px;
		animation: pulse 2.5s 0.2s infinite ease;
	}

	&__loader-container {
		position: absolute;
		top: 40px;
		left: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		background: $primary;
		border-radius: 50%;
		animation: pulse 2.5s infinite ease;
	}
}

.background-shapes {
	z-index: -1;

	&__left {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&__right {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

.loading-url {
	margin-bottom: 4px;
	color: $grey-800;
}

.loading-text {
	margin-bottom: 200px;
	text-align: center;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3);
	}

	100% {
		transform: scale(1);
	}
}
</style>
