<template>
	<div class="choice-container">
		<div
			v-if="!currentlyChoosing"
			class="options"
		>
			<h5 class="options__category z-subheading">
				{{ currentChoices.category.id }}
			</h5>
			<div
				v-if="currentChoices.category"
				class="current-options"
			>
				<span
					v-if="currentChoices.subcategory"
					class="current-options__option z-h5"
				>
					{{ currentChoices.type.id }}
				</span>
				<span class="current-options__option z-h5 current-options__option--blank">
					&nbsp;{{ $t('builder.AIWriter.text') }}&nbsp;
				</span>
				<span
					v-if="currentChoices.type"
					class="current-options__option z-h5"
				>
					{{ currentChoices.subcategory.id }}
				</span>
			</div>
			<ZyroButton
				theme="outline"
				class="options__toggle-button"
				@click.stop="$emit('toggle-choosing')"
			>
				{{ $t('common.change') }}
			</ZyroButton>
		</div>
		<form
			v-else
			class="selection-form"
			@submit.prevent="generateText"
		>
			<div class="choice-list">
				<h6 class="choice-list__title z-subheading">
					1. {{ $t('builder.AIWriter.choices.selectTopic') }}
					<button
						v-if="initialChoices"
						class="choice-list__back-icon"
						@click.stop="(currentChoices = { ...initialChoices }), $emit('toggle-choosing')"
					>
						<ZyroSvg name="arrow-right" />
					</button>
				</h6>
				<ul
					v-qa="'ai-writer-categories'"
					class="choice-list__buttons"
				>
					<!-- TODO: should be a radio-group (accessibility, html semantics). Component? -->
					<button
						v-for="category in categories"
						:key="category.id"
						v-qa="`aiwriter-category-${category.id}`"
						:class="{ 'choice-list__button--active': category === currentChoices.category }"
						class="choice-list__button"
						type="button"
						@click="chooseCategory(category)"
					>
						{{ category.id }}
					</button>
				</ul>
			</div>

			<div
				v-if="currentChoices.category"
				class="choice-list"
			>
				<h6 class="choice-list__title z-subheading">
					2. {{ $t('builder.AIWriter.choices.selectCategory') }}
				</h6>
				<ul
					v-qa="'ai-writer-subcategories'"
					class="choice-list__buttons"
				>
					<TransitionGroup name="drop">
						<button
							v-for="subcategory in currentChoices.category.subcategories"
							:key="subcategory.id"
							v-qa="`aiwriter-subcategory-${subcategory.id}`"
							:class="{ 'choice-list__button--active': subcategory === currentChoices.subcategory }"
							class="choice-list__button"
							type="button"
							@click="chooseSubcategory(subcategory), $emit('type-selected')"
						>
							{{ subcategory.id }}
						</button>
					</TransitionGroup>
				</ul>
			</div>

			<div
				v-if="currentChoices.subcategory"
				ref="typeList"
				class="choice-list"
			>
				<h6 class="choice-list__title z-subheading">
					3. {{ $t('builder.AIWriter.choices.selectParagraph') }}
				</h6>
				<ul
					v-qa="'ai-writer-types'"
					class="choice-list__buttons"
				>
					<TransitionGroup name="drop">
						<button
							v-for="type in currentChoices.subcategory.types"
							:key="type.id"
							v-qa="`aiwriter-type-${type.id}`"
							:class="{ 'choice-list__button--active': type === currentChoices.type }"
							class="choice-list__button"
							type="button"
							@click="currentChoices.type = type"
						>
							{{ type.id }}
						</button>
					</TransitionGroup>
				</ul>
			</div>
			<ZyroLoader v-if="loading" />

			<div class="selection-form__button-container">
				<ZyroButton
					type="submit"
					class="generate-button"
					data-qa="builder-aiwriter-btn-generatetext"
					theme="primary"
					color="red"
					size="sm"
					icon-left="refresh-icon"
					:disabled="!currentChoices.type"
				>
					{{ $t('builder.AIWriter.btn') }}
				</ZyroButton>
			</div>
		</form>
	</div>
</template>
<script>
import {
	getCategories,
	getSubcategories,
	getTypes,
} from '@/api/AiApi';

export default {
	props: {
		currentlyChoosing: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			loading: false,
			initialChoices: null,
			currentChoices: {
				category: null,
				subcategory: null,
				type: null,
			},
			categories: null,
			lastContentOffsetBottom: null,
		};
	},
	mounted() {
		this.loadCategories();
	},
	methods: {
		generateText() {
			this.initialChoices = { ...this.currentChoices };
			this.$emit('generate-text', {
				category: this.currentChoices.category.id,
				subcategory: this.currentChoices.subcategory.id,
				type: this.currentChoices.type.id,
			});
		},
		async loadCategories() {
			this.loading = true;
			const { data } = await getCategories();

			this.categories = data;
			this.loading = false;
		},
		async loadSubcategories() {
			this.loading = true;
			this.$emit('on-content-update');

			const { data } = await getSubcategories(this.currentChoices.category);

			this.currentChoices.category.subcategories = data;
			await this.$forceUpdate();
			this.$emit('on-content-update');
			this.loading = false;
		},
		async loadTypes() {
			this.loading = true;
			this.$emit('on-content-update');

			const { data } = await getTypes(this.currentChoices.subcategory);

			this.currentChoices.subcategory.types = data;
			await this.$forceUpdate();
			this.$emit('on-content-update');
			this.loading = false;
		},
		async chooseCategory(category) {
			this.currentChoices.category = category;
			this.currentChoices.subcategory = null;
			this.currentChoices.type = null;
			if (!this.currentChoices.category.subcategories) {
				await this.loadSubcategories();
			} else {
				this.$emit('on-content-update');
			}
		},
		async chooseSubcategory(subcategory) {
			this.currentChoices.subcategory = subcategory;
			this.currentChoices.type = null;
			if (!this.currentChoices.subcategory.types) {
				await this.loadTypes();
			} else {
				this.$emit('on-content-update');
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.choice-container {
	padding: 0 24px;
}

.options {
	display: flex;
	flex-wrap: wrap;

	&__toggle-button {
		margin-bottom: 24px;
		cursor: pointer;
	}

	&__category {
		margin-bottom: 24px;
		color: $grey-800;
	}
}

.selection-form {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__button-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 24px 0;
		overflow: hidden;
		background-color: $light;
	}
}

.current-options {
	margin-bottom: 24px;

	&__option {
		font-weight: 300;
		color: $secondary;

		&--blank {
			color: $grey-800;
		}
	}
}

.choice-list {
	&:not(:first-child) {
		margin-top: 30px;
	}

	&__title {
		display: flex;
		justify-content: space-between;
		padding-bottom: 8px;
		margin-bottom: 15px;
		border-bottom: 1px solid $grey-100;
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
	}

	&__button {
		padding: 7px 14px;
		margin: 0 10px 10px 0;
		color: $grey-600;
		text-transform: capitalize;
		background-color: $light;
		border: 1px solid $grey-300;
		border-radius: 16px;
		transition: all 0.2s;

		&:hover,
		&:focus {
			color: $secondary-light;
			cursor: pointer;
			border: 1px solid $secondary-light;
		}

		&--active {
			color: $secondary;
			border-color: $secondary;
		}
	}

	&__back-icon {
		cursor: pointer;
	}
}

.generate-button {
	&:not(.s) {
		&:disabled {
			color: $secondary;
			background-color: $light;
			border-color: $secondary;
			border-width: 1px;
		}
	}
}

// Transition classes
.drop {
	&-enter-active {
		transition: all 0.4s;
	}

	&-leave-active {
		transition: none;
	}

	&-enter {
		opacity: 1;
		transform: translateY(-20px);
	}
}
</style>
