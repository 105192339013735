<template>
	<div
		v-if="pages && currentPage"
		class="content"
	>
		<h3 class="z-h5">
			{{ $t('builder.websiteImporter.content.title') }}
		</h3>
		<div class="content__url">
			<p class="content__url-text z-body-small">
				{{ urlWithoutHttp }}
			</p>
			<ZyroButton
				v-qa="'websiteimporter-edit'"
				icon="edit"
				theme="outline"
				@click="$emit('edit-url')"
			/>
		</div>
		<ZyroTabs
			:tabs="tabs"
			:current-tab="currentTab"
			class="content__tabs"
			@change="currentTab = $event"
		/>
		<div class="content__dropdown-title z-body-small">
			{{ $t('builder.websiteImporter.content.from') }}
		</div>
		<ZyroDropdown
			v-qa="'websiteimporter-dropdown-page'"
			:options="pages"
			:current.sync="currentPage"
			class="content__dropdown"
		/>
		<div
			v-if="currentTab && currentTab.title === $t('common.images')"
			v-qa="'websiteimporter-images'"
			class="images"
		>
			<masonry
				:cols="2"
				:gutter="16"
			>
				<img
					v-for="(image, index) in images"
					:key="`imported-image-${index}`"
					:src="image.settings.image"
					:alt="image.settings.alt"
					class="images__image"
					@dragstart="onDragStart($event, index, image)"
					@click="showAndShakeDragAndDropMessage"
				>
			</masonry>
		</div>
		<div
			v-if="currentTab && currentTab.title === $t('common.texts')"
			v-qa="'websiteimporter-texts'"
			class="texts"
		>
			<p
				v-for="(text, index) in texts"
				:key="`imported-text-${index}`"
				:class="`texts__text ${text.content.length < 25 ? 'z-h5' : 'z-body-small'}`"
				draggable="true"
				@dragstart="onDragStart($event, index, text)"
				@click="showAndShakeDragAndDropMessage"
			>
				{{ text.content }}
			</p>
		</div>
		<div
			v-if="showDragAndDropMessage"
			class="drag-and-drop-message"
			:class="{ 'drag-and-drop-message--shake': shakeDragAndDropMessage }"
			@animationend="shakeDragAndDropMessage = false"
		>
			<ZyroSvg name="drag-and-drop" />
			<div class="drag-and-drop-message__text">
				<div class="drag-and-drop-message__title z-body-small z-body-small--strong">
					{{ $t('builder.websiteImporter.content.dragAndDrop.title') }}
				</div>
				<div class="drag-and-drop-message__description z-body-small">
					{{ $t('builder.websiteImporter.content.dragAndDrop.message') }}
				</div>
			</div>
			<ZyroButton
				class="drag-and-drop-message__close"
				icon="x"
				:title="$t('common.close')"
				@click="showDragAndDropMessage = false"
			/>
		</div>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';

import { websiteImporterElements } from '@/components/builder-controls/add-element/elements';
import { WEBSITE_IMPORTER_DRAWER } from '@/store/builder/constants/drawers';

const ALL_PAGES_ID = 'allPages';
const GRID_TEXT_BOX = 'GridTextBox';
const GRID_IMAGE = 'GridImage';

export default {
	props: {
		content: {
			type: Object,
			default: () => ({}),
		},
		contentUrl: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			currentPage: null,
			currentTab: null,
			showDragAndDropMessage: true,
			shakeDragAndDropMessage: false,
		};
	},
	computed: {
		pages() {
			const allPagesOption = {
				id: ALL_PAGES_ID,
				title: this.$t('common.allPages'),
			};
			const importedPages = Object.entries(this.content.pages)
				.map(([id, value]) => ({
					...value,
					id,
					title: value.name,
				}));

			return importedPages.length > 1
				? [
					allPagesOption,
					...importedPages,
				]
				: [...importedPages];
		},
		images() {
			return this.currentPage && this.getContent(GRID_IMAGE);
		},
		texts() {
			return this.currentPage && this.getContent(GRID_TEXT_BOX);
		},
		tabs() {
			const tabs = [];

			if (this.images?.length) {
				tabs.push({ title: this.$t('common.images') });
			}

			if (this.texts?.length) {
				tabs.push({ title: this.$t('common.texts') });
			}

			return tabs;
		},
		urlWithoutHttp() {
			return this.contentUrl.replace(/(^\w+:|^)\/\//, '');
		},
	},
	watch: {
		content(oldValue, newValue) {
			if (!oldValue || !newValue) {
				return;
			}

			this.resetCurrentPageAndTab();
		},
	},
	mounted() {
		this.resetCurrentPageAndTab();
	},
	methods: {
		getContent(type) {
			const {
				components: allComponents,
				blocks: allBlocks,
			} = this.content;
			const currentComponents = this.currentPage.id === ALL_PAGES_ID
				? Object.values(allComponents)
				: this.currentPage.blocks?.reduce((acc, item) => {
					const blockComponents = allBlocks[item].components.map(
						(component) => allComponents[component],
					);

					return [
						...acc,
						...blockComponents,
					];
				}, []);

			return currentComponents?.filter((component) => component.type === type);
		},
		resetCurrentPageAndTab() {
			[this.currentPage] = this.pages;
			[this.currentTab] = this.tabs;
		},
		onDragStart(e, key, element) {
			const {
				type,
				settings,
				content: elementContent,
			} = element;
			let content = null;

			if (type === GRID_IMAGE) {
				const isSvg = /.svg$/.test(settings.image);

				content = websiteImporterElements.gridImage({
					imageUrl: settings.image,
					alt: settings.alt,
					objectFit: isSvg ? 'contain' : 'cover',
				});
			} else if (type === GRID_TEXT_BOX) {
				content = websiteImporterElements.gridTextBox({ text: elementContent });
			}

			e.dataTransfer.setData('content', JSON.stringify(content));
			e.dataTransfer.setData('elementId', nanoid());
			e.dataTransfer.setData('width', 4);
			e.dataTransfer.setData('height', 6);
			e.dataTransfer.setData('eventData', JSON.stringify({
				from: WEBSITE_IMPORTER_DRAWER,
				type: content.type,
			}));
		},
		showAndShakeDragAndDropMessage() {
			if (!this.showDragAndDropMessage) {
				this.showDragAndDropMessage = true;
			}

			this.shakeDragAndDropMessage = true;
		},
	},
	ALL_PAGES_ID,
	GRID_TEXT_BOX,
	GRID_IMAGE,
};
</script>

<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;

	&__url {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	&__url-text {
		margin-right: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__dropdown-title {
		margin-bottom: 6px;
	}

	&__dropdown {
		margin-bottom: 16px;
	}

	&__tabs {
		margin-bottom: 24px;
	}
}

.images {
	flex-grow: 1;
	overflow-y: auto;

	&__image {
		width: 100%;
		cursor: move;
	}
}

.texts {
	flex-grow: 1;
	overflow-y: auto;

	&__text {
		padding: 16px;
		margin-bottom: 16px;
		cursor: move;
		user-select: none;
		border: 1px solid $grey-200;
		border-radius: $border-radius-small;
	}
}

.drag-and-drop-message {
	position: absolute;
	right: 0;
	bottom: 12px;
	left: 0;
	display: flex;
	max-width: 300px;
	padding: 12px 34px 18px 10px;
	margin: auto;
	background-color: $light;
	box-shadow: $box-shadow;

	&__title {
		margin-bottom: 4px;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
	}

	&--shake {
		animation: shake 0.3s forwards;
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
