<template>
	<div
		v-if="allowEditing"
		:key="`${blockId}-btn-for-transition`"
		class="edit-actions"
		:style="position"
	>
		<template v-if="!isLinkedBlockHidden">
			<div
				v-if="blockType === 'BlockSlideshow'"
				data-qa="builder-section-btn-editslideshow"
				class="edit-actions__button edit-actions__slideshow-buttons-container"
			>
				<ZyroButton
					icon="chevron-left"
					@click="goToPreviousSlideInCurrentActiveSlideshow"
				/>
				<ZyroButton
					class="edit-actions__button-slideshow-manage"
					@click="$emit('edit-block', {
						startingPopupComponent: 'EditBlockSlideshowSettingsTabs',
						tabId: 'slides',
					})"
				>
					<span class="z-body-small">{{ currentSlideshowSlideName }}</span>
				</ZyroButton>
				<ZyroButton
					icon="chevron-right"
					@click="goToNextSlideInCurrentActiveSlideshow"
				/>
			</div>
			<ZyroButton
				v-if="blockType === 'BlockEcwidStore'"
				class="edit-actions__button"
				data-qa="builder-section-btn-managestore"
				theme="editor"
				:title="$t('builder.editStore.title')"
				@click="openStoreSettings"
			>
				{{ $t('builder.editStore.title') }}
			</ZyroButton>
			<ZyroButton
				class="edit-actions__button"
				data-qa="builder-section-btn-changebackground"
				theme="editor"
				@click="openBlockBackground"
			>
				{{ $t('builder.editBlockButton.changeBackground') }}
			</ZyroButton>

			<ZyroButton
				class="edit-actions__button"
				data-qa="builder-section-btn-settings"
				theme="editor"
				icon="settings"
				:title="title"
				@click="openBlockSettings"
			/>

			<ZyroButton
				v-if="allowDuplication"
				class="edit-actions__button"
				data-qa="builder-sectionedit-buttonduplicate"
				theme="editor"
				icon="duplicate"
				:title="$t('common.duplicate')"
				@click="$emit('duplicate-block')"
			/>

			<ZyroButton
				v-if="allowSorting"
				class="edit-actions__button"
				data-qa="builder-sectionedit-buttonsort"
				theme="editor"
				icon="refresh-icon"
				title="Sort"
				@click="$emit('sort-block-components')"
			/>

			<ZyroButton
				v-qa="'builder-section-btn-moveup'"
				class="edit-actions__button"
				theme="editor"
				icon="arrow-up"
				:title="$t('builder.editBlockButton.moveSectionUp')"
				:disabled="!isMovingBlockUpAllowed"
				@click="$emit('move-block-up')"
			/>
			<ZyroButton
				v-qa="'builder-section-btn-movedown'"
				class="edit-actions__button"
				theme="editor"
				icon="arrow-down"
				:title="$t('builder.editBlockButton.moveSectionDown')"
				:disabled="!isMovingBlockDownAllowed"
				@click="$emit('move-block-down')"
			/>
			<ZyroButton
				v-if="allowRemoval"
				class="edit-actions__button"
				data-qa="builder-sectionedit-buttondelete"
				theme="editor"
				icon="trash"
				:title="$t('common.delete')"
				@click="removeBlock(false)"
			/>
		</template>
		<ZyroPopup
			v-if="showLinkedActions"
			type="editor"
			:offset="{
				top: 40,
				right: 0
			}"
			:show-close-button="false"
		>
			<template #trigger>
				<ZyroButton
					data-qa="builder-sectionedit-buttonlinked"
					theme="primary"
					color="plump-purple"
					icon="link"
					:title="$t('common.hide')"
				/>
			</template>
			<div class="edit-actions__hide-block">
				<ZyroButton
					:icon-left="isLinkedBlockHidden ? 'eye-open' : 'eye-closed'"
					@click="$emit('toggle-linked-block', 'footer', false, isLinkedBlockHidden)"
				>
					{{ isLinkedBlockHidden ?
						$t('builder.globalBlock.showOnSingle') : $t('builder.globalBlock.hideOnSingle') }}
				</ZyroButton>
				<ZyroButton
					icon-left="eye-closed"
					@click="$emit('toggle-linked-block', 'footer', true, true)"
				>
					{{ $t('builder.globalBlock.hideOnAll') }}
				</ZyroButton>
				<ZyroButton
					icon-left="eye-open"
					@click="$emit('toggle-linked-block', 'footer', true, false)"
				>
					{{ $t('builder.globalBlock.showOnAll') }}
				</ZyroButton>
			</div>
		</ZyroPopup>
		<SystemDialogModal
			v-if="showRemoveWarning"
			title="Delete linked section"
			:primary-button-text="$t('common.delete')"
			:secondary-button-text="$t('common.cancel')"
			@click-primary="removeBlock(true)"
			@click-secondary="showRemoveWarning = false"
			@close="showRemoveWarning = false"
		>
			{{ $t('builder.editBlockButton.removeWarningText') }}
		</SystemDialogModal>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { useEditBlockSlideshow } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshow';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import i18n from '@/i18n/setup';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { getMapValue } from '@/utils/object';

const blockTitles = {
	BlockNavigation: i18n.t('builder.editBlockButton.editHeader'),
	BlockBlogList: i18n.t('builder.editBlockButton.editBlogList'),
	BlockBlogHeader: i18n.t('builder.editBlockButton.editBlogHeader'),
	default: i18n.t('builder.editBlockButton.editSection'),
};

const allowedEditableBlockTypes = { default: true };

const allowedRemovableBlockTypes = {
	BlockNavigation: false,
	BlockBlogHeader: false,
	default: true,
};

const allowedDuplicationTypes = {
	BlockNavigation: false,
	BlockBlogHeader: false,
	BlockEcwidStore: false,
	default: true,
};

const allowedDuplicationIds = {
	navigation: false,
	default: true,
};

const allowedDuplicationSlots = {
	footer: false,
	default: true,
};

const allowedLinkedActionsSlots = {
	footer: true,
	default: false,
};

const slotsWithRemoveWarning = {
	footer: true,
	default: false,
};

export default {
	components: { SystemDialogModal },
	props: {
		position: {
			type: Object,
			required: true,
		},
		blockType: {
			type: String,
			required: true,
		},
		blockSlot: {
			type: String,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
		isLinkedBlockHidden: {
			type: Boolean,
			default: false,
		},
		isMovingBlockUpAllowed: {
			type: Boolean,
			required: true,
		},
		isMovingBlockDownAllowed: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, context) {
		const {
			currentSlideshowSlideName,
			goToPreviousSlideInCurrentActiveSlideshow,
			goToNextSlideInCurrentActiveSlideshow,
		} = useEditBlockSlideshow(props, context);

		return {
			currentSlideshowSlideName,
			goToPreviousSlideInCurrentActiveSlideshow,
			goToNextSlideInCurrentActiveSlideshow,
		};
	},
	data() {
		return { showRemoveWarning: false };
	},
	computed: {
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		...mapState(['website']),
		title() {
			return getMapValue(this.blockType, blockTitles);
		},
		allowEditing() {
			return getMapValue(this.blockType, allowedEditableBlockTypes);
		},
		allowRemoval() {
			return getMapValue(this.blockType, allowedRemovableBlockTypes);
		},
		allowDuplication() {
			return getMapValue(this.blockType, allowedDuplicationTypes)
			&& getMapValue(this.blockId, allowedDuplicationIds)
			&& getMapValue(this.blockSlot, allowedDuplicationSlots);
		},
		showLinkedActions() {
			return getMapValue(this.blockSlot, allowedLinkedActionsSlots);
		},
		allowSorting() {
			return (this.blockType === 'BlockGrid' && (this.isMobileView || this.isMobileScreen));
		},
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapActions('subscription', ['autoConnectPlan']),
		removeBlock(forceRemove) {
			if (getMapValue(this.blockSlot, slotsWithRemoveWarning) && !forceRemove) {
				this.showRemoveWarning = true;
			} else {
				this.$emit('remove-block');
			}
		},
		async openStoreSettings() {
			await this.autoConnectPlan({ ecommerceOnly: true });
			EventLogApi.logEvent({ eventName: 'builder.storefront.click_button(manage_store)' });
			this.openModal({ name: 'EditOnlineStoreModal' });
		},
		openBlockSettings() {
			if (this.blockType === 'BlockSlideshow') {
				this.$emit('edit-block', {
					startingPopupComponent: 'EditBlockSlideshowSettingsTabs',
					tabId: 'style',
				});
			} else {
				this.$emit('edit-block');
			}
		},
		openBlockBackground() {
			if (this.blockType === 'BlockSlideshow') {
				this.$emit('edit-block', { startingPopupComponent: 'EditBlockSlideshowSlideBackground' });
			} else {
				this.$emit('edit-block', { tabId: 'background' });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-actions {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	justify-content: center;

	&__button {
		flex-shrink: 0;

		&:not(:last-child) {
			margin-right: 8px;
		}
	}

	&__hide-block {
		display: flex;
		flex-direction: column;
	}

	&__slideshow-buttons-container {
		display: flex;
		background-color: $light;
		border-radius: 100px;
	}

	&__button-slideshow-manage {
		padding: 0 12px;
	}
}
</style>
