import { Node } from 'tiptap';
import { toggleBlockType } from 'tiptap-commands';

import {
	TEXT_TYPES,
	BODY_CLASS,
	P_TAG,
	PARAGRAPH_NODE_NAME,
} from '@/use/text-editor/constants';
import { sanitizeTagStyles } from '@/use/text-editor/utils';

export default class Paragraph extends Node {
	get name() {
		return PARAGRAPH_NODE_NAME;
	}

	get schema() {
		return {
			attrs: {
				className: { default: BODY_CLASS },
				style: { default: null },
			},
			content: 'inline*',
			group: 'block',
			draggable: false,
			parseDOM: [
				...Object.keys(TEXT_TYPES).map((className) => ({
					tag: `${P_TAG}.${className}`,
					getAttrs: (dom) => ({
						className,
						style: dom.getAttribute('style'),
					}),
				})),
				{
					tag: P_TAG,
					getAttrs: (dom) => ({
						className: BODY_CLASS,
						style: dom.getAttribute('style'),
					}),
				},
			],
			toDOM: ({
				attrs: {
					className,
					style,
				},
			}) => [
				P_TAG,
				{
					class: className,
					styles: sanitizeTagStyles(style),
				},
				0,
			],
		};
	}

	commands({
		type,
		schema,
	}) {
		return (attributes) => toggleBlockType(type, schema.nodes.paragraph, attributes);
	}
}
