import { Mark } from 'tiptap';
import {
	updateMark,
	removeMark,
	pasteRule,
} from 'tiptap-commands';

import { LINK_MARK_NAME } from '@/use/text-editor/constants';

export default class LinkMark extends Mark {
	get name() {
		return LINK_MARK_NAME;
	}

	get schema() {
		return {
			attrs: {
				href: { default: null },
				target: { default: null },
				internalLink: { default: null },
				title: { default: null },
			},
			inclusive: false,
			parseDOM: [
				{
					tag: 'a[href]',
					getAttrs: (dom) => ({
						href: dom.getAttribute('href'),
						target: dom.getAttribute('target'),
						title: dom.getAttribute('title'),
						internalLink: dom.getAttribute('data-internal-link'),
					}),
				},
			],
			toDOM: ({
				attrs: {
					href,
					target,
					title,
					internalLink,
				},
			}) => [
				'a',
				{
					href,
					target,
					title,
					'data-internal-link': internalLink,
				},
				0,
			],
		};
	}

	commands({ type }) {
		return (attributes) => (attributes.href ? updateMark(type, attributes) : removeMark(type));
	}

	pasteRules({ type }) {
		return [pasteRule(/https?:\/\/(www\.)?[\w#%+.:=@~-]{1,256}\.[a-z]{2,}\b([\w#%&+,./:=?@~-]*)/gi, type, (url) => ({ href: url }))];
	}
}
