<template>
	<ContextMenu @close-context-menu="rightClickedElementId = null">
		<template v-for="action in ACTIONS">
			<ZyroSeparator
				v-if="action.isSeparator"
				:key="action.title"
			/>
			<ContextMenuButton
				v-else
				:key="action.title"
				:title="action.title"
				:is-disabled="getComputedPropertyValue(action.isDisabledWhen)"
				:shortcut="action.shortcut"
				@click.native="callFunction(action.function, action.functionParams)"
			/>
		</template>
	</ContextMenu>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapMutations,
	mapState,
} from 'vuex';

import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useGridElementContextMenu } from '@/components/context-menu/useGridElementContextMenu';
import i18n from '@/i18n/setup';
import {
	moveRight,
	moveLeft,
} from '@/utils/array';

import ContextMenu from './ContextMenu.vue';
import ContextMenuButton from './ContextMenuButton.vue';

const UPDATE_ZINDEX_FUNCTION = 'updateZIndex';
const IS_ELEMENT_ZINDEX_MAX_COMPUTED = 'isElementZindexMax';
const IS_ELEMENT_ZINDEX_MIN_COMPUTED = 'isElementZindexMini';
const ACTIONS = [
	{
		title: i18n.t('builder.contextMenu.gridItem.sendFront'),
		shortcut: {
			mac: '⌥ ⌘ ]',
			windows: 'Ctrl+Shift+]',
		},
		function: UPDATE_ZINDEX_FUNCTION,
		isDisabledWhen: IS_ELEMENT_ZINDEX_MAX_COMPUTED,
		functionParams: {
			shouldMoveLeft: false,
			shouldMoveFully: true,
		},
	},
	{
		title: i18n.t('builder.contextMenu.gridItem.moveForward'),
		shortcut: {
			mac: '⌘ ]',
			windows: 'Ctrl+]',
		},
		function: UPDATE_ZINDEX_FUNCTION,
		isDisabledWhen: IS_ELEMENT_ZINDEX_MAX_COMPUTED,
		functionParams: {
			shouldMoveLeft: false,
			shouldMoveFully: false,
		},
	},
	{
		title: i18n.t('builder.contextMenu.gridItem.sendBack'),
		shortcut: {
			mac: '⌥ ⌘ [',
			windows: 'Ctrl+Shift+[',
		},
		function: UPDATE_ZINDEX_FUNCTION,
		isDisabledWhen: IS_ELEMENT_ZINDEX_MIN_COMPUTED,
		functionParams: {
			shouldMoveLeft: true,
			shouldMoveFully: true,
		},
	},
	{
		title: i18n.t('builder.contextMenu.gridItem.moveBackward'),
		shortcut: {
			mac: '⌘ [',
			windows: 'Ctrl+[',
		},
		function: UPDATE_ZINDEX_FUNCTION,
		isDisabledWhen: IS_ELEMENT_ZINDEX_MIN_COMPUTED,
		functionParams: {
			shouldMoveLeft: true,
			shouldMoveFully: false,
		},
	},
	{ isSeparator: true },
	{
		title: i18n.t('common.duplicate'),
		shortcut: {
			mac: '⌘ D',
			windows: 'Ctrl+D',
		},
		function: 'duplicateCurrentElement',
	},
	{
		title: i18n.t('common.delete'),
		shortcut: {
			mac: '⌫',
			windows: 'Backspace',
		},
		function: 'removeCurrentElement',
	},
];

export default {
	components: {
		ContextMenu,
		ContextMenuButton,
	},
	setup() {
		const { rightClickedElementId } = useGridElementContextMenu();
		const { closeContextMenu } = useContextMenu();

		return {
			rightClickedElementId,
			closeContextMenu,
			ACTIONS,
		};
	},
	computed: {
		...mapGetters([
			'getCurrentElementBlockId',
			'currentElement',
		]),
		...mapState(['website']),
		blockZIndexes() {
			return this.website.blocks[this.getCurrentElementBlockId]?.zindexes || [];
		},
		[IS_ELEMENT_ZINDEX_MAX_COMPUTED]() {
			return this.blockZIndexes[this.blockZIndexes.length - 1] === this.rightClickedElementId;
		},
		[IS_ELEMENT_ZINDEX_MIN_COMPUTED]() {
			return this.blockZIndexes[0] === this.rightClickedElementId;
		},
	},
	watch: {
		// Closes menu when other shortcuts are used to modfiy the element
		currentElement() {
			this.rightClickedElementId = null;
			this.closeContextMenu();
		},
	},
	methods: {
		...mapMutations(['setBlockData']),
		...mapActions([
			'duplicateCurrentElement',
			'removeCurrentElement',
		]),
		getComputedPropertyValue(computedPropertyName) {
			return this[computedPropertyName];
		},
		callFunction(functionName, params) {
			this[functionName](params);
			// Closes contex menu
			this.closeContextMenu();
		},
		[UPDATE_ZINDEX_FUNCTION](params) {
			const {
				shouldMoveLeft = false,
				shouldMoveFully = false,
			} = params;
			const zindexes = shouldMoveLeft
				? moveLeft(this.blockZIndexes, this.rightClickedElementId, shouldMoveFully)
				: moveRight(this.blockZIndexes, this.rightClickedElementId, shouldMoveFully);

			this.setBlockData({
				blockId: this.getCurrentElementBlockId,
				data: { zindexes },
				skipHistory: false,
			});
		},
	},
};
</script>
