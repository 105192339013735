import VueCompositionApi, {
	ref,
	computed,
} from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionApi);

/**
 * When these are not null the base context menu uses it to position itself
 * When either of these aren't set the menu is closed
 */
const mouseEventX = ref(null);
const mouseEventY = ref(null);

export const useContextMenu = () => {
	const mouseEvent = computed(() => {
		if (!mouseEventX.value || !mouseEventY.value) {
			return null;
		}

		return {
			clientX: mouseEventX.value,
			clientY: mouseEventY.value,
		};
	});

	// Opens context menu
	const setMouseEvent = (event) => {
		mouseEventX.value = event.clientX;
		mouseEventY.value = event.clientY;
	};

	// Closes context menu
	const closeContextMenu = () => {
		mouseEventX.value = null;
		mouseEventY.value = null;
	};

	return {
		mouseEvent,
		setMouseEvent,
		closeContextMenu,
	};
};
