<template>
	<Component
		:is="currentEditor"
		:key="`${currentBlockId}-for-transition`"
		:start-tab-id="startTabId"
		:starting-popup-component="startingPopupComponent"
		class="edit-block-popup"
		:style="position"
		@close="$emit('close')"
	/>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import { getMapValue } from '@/utils/object';

const editorTypes = {
	BlockNavigation: 'EditBlockNavigationTabs',
	BlockSlideshow: 'EditBlockSlideshow',
	BlockBlogList: 'EditBlockBlogListTabs',
	BlockBlogHeader: 'EditBlockBlogHeaderTabs',
	BlockEcwidStore: 'EditBlockEcwidStoreTabs',
	default: 'EditBlockTabs',
};

export default {
	components: {
		EditBlockTabs: () => import('./edit-block/EditBlockTabs.vue'),
		EditBlockNavigationTabs: () => import('./edit-block-navigation/EditBlockNavigationTabs.vue'),
		EditBlockBlogListTabs: () => import('./edit-block-blog-list/EditBlockBlogListTabs.vue'),
		EditBlockBlogHeaderTabs: () => import('./edit-block-blog-header/EditBlockBlogHeaderTabs.vue'),
		EditBlockEcwidStoreTabs: () => import('./edit-block-ecwid-store/EditBlockEcwidStoreTabs.vue'),
		EditBlockSlideshow: () => import('./edit-block-slideshow/EditBlockSlideshow.vue'),
	},
	props: {
		position: {
			type: Object,
			required: true,
		},
		startTabId: {
			type: String,
			default: '',
		},
		startingPopupComponent: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		currentEditor() {
			return getMapValue(this.currentBlock.type, editorTypes);
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-block-popup {
	position: absolute;
}
</style>
