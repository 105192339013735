<template>
	<Transition name="fade-in">
		<!-- @mousedown.native.stop makes it so only clicks outside close the menu -->
		<PopupFrame
			v-if="renderMenu"
			ref="contextMenu"
			class="context-menu"
			:left="computedStyles.left"
			:top="computedStyles.top"
			@mousedown.native.stop
		>
			<slot />
		</PopupFrame>
	</Transition>
</template>

<script>
import PopupFrame from '@/components/context-menu/PopupFrame.vue';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { usePositionPopups } from '@/use/usePositionPopups';

export default {
	components: { PopupFrame },
	setup() {
		const {
			mouseEvent,
			closeContextMenu,
		} = useContextMenu();
		const { fitCoordinatesToScreen } = usePositionPopups();

		return {
			fitCoordinatesToScreen,
			closeContextMenu,
			mouseEvent,
		};
	},
	data() {
		return { renderMenu: false };
	},
	computed: {
		computedStyles() {
			const {
				clientX,
				clientY,
			} = this.mouseEvent;
			const {
				left,
				top,
			} = this.fitCoordinatesToScreen(
				this.$refs.contextMenu?.$el,
				document.body,
				{
					left: clientX,
					top: clientY,
				},
			);

			return {
				left: `${left}px`,
				top: `${top + window.scrollY}px`,
			};
		},
	},
	watch: {
		async mouseEvent(newValue) {
			// Trigger animation
			this.renderMenu = false;
			if (newValue) {
				this.renderMenu = true;
				// Wait for popup to render so we can place it properly
				await this.$nextTick();
				this.$recompute('computedStyles');
			}
		},
	},
	created() {
		window.addEventListener('mousedown', this.hideMenu);
	},
	beforeDestroy() {
		window.removeEventListener('mousedown', this.hideMenu);
	},
	methods: {
		hideMenu() {
			this.closeContextMenu();
			this.$emit('close-context-menu');
		},
	},
};
</script>

<style lang="scss" scoped>

.context-menu {
	min-width: 230px;
}

.fade-in {
	transform-origin: 50% -50px;

	&-enter-active,
	&-leave-active {
		transition: transform 0.25s, opacity 0.2s;
	}

	&-enter,
	&-leave-to {
		opacity: 0;
		transform: rotateX(-10deg) scaleY(0.98) scaleX(0.99);
	}
}

</style>
