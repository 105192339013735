<template>
	<div
		:key="`${blockId}-section-control-transition`"
		class="section-control"
		:style="position"
		@click="setEditMode(false)"
	>
		<div
			class="section-control__container"
			:style="{ maxWidth: maxWidth }"
		>
			<AddBlockButton
				:class="{
					'section-control__button--invisible' :
						!displayAddBlock || activeEventInfo.blockId
				}"
				:block-id="blockId"
				:block-ref="blockRef"
			/>
			<div
				class="section-control__resize-handle"
			>
				<ZyroTooltip
					position="top"
					toggle-event="hover"
					:hide-tooltip="blockIsLocked"
					:use-portal="false"
					content-position="absolute"
					:forced-position="{
						left: '-39px',
						bottom: '40px',
						'white-space': 'nowrap'
					}"
				>
					<template #trigger>
						<ResizeButtonHandle
							:block-id="blockId"
							:block-ref="blockRef"
							:active-event-info.sync="activeEventInfo"
							@lock-hovered-block="blockIsLocked = $event"
						/>
					</template>
					<p class="section-control__resize-handle-text z-body-small">
						{{ $t("builder.sectionControlLine.text") }}
					</p>
				</ZyroTooltip>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
} from 'vuex';

import AddBlockButton from './AddBlockButton.vue';
import ResizeButtonHandle from './ResizeBlockHandle.vue';

export default {
	components: {
		ResizeButtonHandle,
		AddBlockButton,
	},
	props: {
		position: {
			type: Object,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
		displayAddBlock: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			blockIsLocked: false,
			activeEventInfo: {},
		};
	},
	computed: {
		...mapState(['website']),
		maxWidth() {
			if (this.website.blocks[this.blockId] === 'BlockGrid') {
				return this.website.blocks[this.blockId].settings.styles['grid-width'];
			}

			return '1200px';
		},
	},
	watch: {
		blockIsLocked(newValue) {
			this.$emit('lock-hovered-block', newValue);
		},
		activeEventInfo(newValue) {
			this.$emit('update:activeEventInfo', newValue);
		},
	},
	methods: { ...mapActions('gui', ['setEditMode']) },
};
</script>

<style lang="scss" scoped>
.section-control {
	position: absolute;
	right: 0;
	left: 0;
	z-index: z-index(controls--section-control);
	display: flex;
	width: 100%;
	pointer-events: none;
	place-content: center;

	&__container {
		position: relative;
		z-index: z-index(controls--edit); // button container should be above block edit level
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	&__resize-handle {
		position: absolute;
		right: -30px;
		padding: 10px 15px;
		pointer-events: all;

		// Most common grid width
		@media screen and (max-width: $media-common-grid) {
			right: 0;
		}

		&-text {
			margin: 0;
		}
	}

	&__button {
		&--invisible {
			// Cant hide this with v-if or v-show, because then it loses height
			opacity: 0;
			transition: opacity 0.2s $easing-standard;
		}
	}
}
</style>
