import { Mark } from 'tiptap';
import {
	updateMark,
	removeMark,
} from 'tiptap-commands';

import {
	LETTERCASES,
	LETTERCASE_MARK_NAME,
} from '@/use/text-editor/constants';

export default class LetterCaseMark extends Mark {
	get name() {
		return LETTERCASE_MARK_NAME;
	}

	get defaultOptions() {
		return { letterCases: LETTERCASES };
	}

	get schema() {
		return {
			attrs: { letterCase: { default: '' } },
			parseDOM: this.options.letterCases.map((letterCase) => ({
				style: `text-transform=${letterCase}`,
				attrs: { letterCase },
			})),
			toDOM: (mark) => [
				'span',
				{ style: `text-transform: ${mark.attrs.letterCase}` },
				0,
			],
		};
	}

	commands({ type }) {
		return (attributes) => (attributes.letterCase ? updateMark(type, attributes) : removeMark(type));
	}
}
