<template>
	<button
		class="context-menu-button z-body-small"
		:disabled="isDisabled"
	>
		{{ title }}
		<span
			v-if="shortcut"
			class="context-menu-button__shortcut"
		>
			{{ isMac ? shortcut.mac : shortcut.windows }}
		</span>
	</button>
</template>

<script>

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		shortcut: {
			type: Object,
			default: () => ({}),
			validator: (shortcut) => 'mac' in shortcut && 'windows' in shortcut,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const isMac = navigator.platform.toUpperCase().includes('MAC');

		return { isMac };
	},
};
</script>

<style lang="scss" scoped>
.context-menu-button {
	display: flex;
	width: 100%;
	padding: 10px 16px;

	&:disabled {
		color: $grey-600;
	}

	&:hover,
	&:focus-visible {
		&:not(:disabled) {
			cursor: pointer;
			background: $grey-100;
		}
	}

	&__shortcut {
		margin-left: auto;
		color: $grey-600;
		letter-spacing: 0.15em;
	}
}
</style>
