import {
	ref,
	reactive,
	computed,
} from '@vue/composition-api';
import axios from 'axios';
import { nanoid } from 'nanoid';

import { getErrorMessage } from '@/use/api/errorMessage';
import { useNotifications } from '@/use/useNotifications';
import { useRecaptcha } from '@/use/useRecaptcha';

const windowId = window.sessionStorage.getItem('z-window-id') ?? nanoid();

window.sessionStorage.setItem('z-window-id', windowId);

// default axios request config
const DEFAULT_CONFIG = {
	method: 'get',
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		common: { Accept: 'application/json' },
		'z-app': 'builder',
		'z-window-id': windowId,
	},
	withCredentials: true,
	withReCaptcha: false,
};

export const useApi = () => {
	const isLoading = ref(false);
	const hasLoaded = ref(false);
	const hasFailed = ref(false);

	const error = ref(null);
	const result = ref(null);
	const requestConfig = reactive({});

	const {
		fallback,
		getToken,
		setFallback,
	} = useRecaptcha();
	const { notify } = useNotifications();

	const errorMessage = computed(() => error.value?.message);
	const reCaptchaSvg = computed(() => error.value?.response?.data?.captcha);

	const errorHandler = (error_, errorNotification, showToast) => {
		hasFailed.value = true;
		const message = errorNotification.message ?? getErrorMessage(error_);

		if (showToast) {
			notify({
				...errorNotification,
				message,
			});
		}

		error.value = {
			...error_,
			message,
		};
		// TODO: Failed requests are logged in test. Find a way to disable that.
	};

	/**
	 *
	 * @param url {string}
	 * @param config {{
	 *    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
	 *    [data]: Object
	 * }}
	 * @param errorNotification {{
	 *    [type]: NOTIFICATION_TYPE_TOAST | NOTIFICATION_TYPE_MODAL | NOTIFICATION_TYPE_RENDERLESS,
	 *    [origin]: string,
	 *    [title]: string,
	 *    [message]: string,
	 *    [isClosable]: boolean,
	 *    [autoClose]: boolean,
	 *    [autoSubmit]: boolean,
	 *    [submitLabel]: string,
	 *    [submitCallback]: function,
	 *    [closeCallback]: function,
	 *    [closeOnSubmit]: boolean,
	 *    [props]: Object
	 * }},
	 * @returns {Promise<void>}
	 */
	const callApi = async (url, config = {}, errorNotification = {}, showToast = true) => {
		isLoading.value = true;
		hasLoaded.value = false;
		hasFailed.value = false;
		error.value = null;
		requestConfig.value = {
			url,
			...DEFAULT_CONFIG,
			...config,
		};

		if (requestConfig.value.data?.withRecaptcha) {
			requestConfig.value.data.token = await getToken();

			// if fallback has value, add it to request
			if (fallback.value) {
				requestConfig.value.data.captchaFallback = fallback.value;
			}
		}

		try {
			const { data } = await axios(requestConfig.value);

			if (data) {
				result.value = data;
				hasLoaded.value = true;
			} else {
				errorHandler(new Error('A malformed response'));
			}
		} catch (error_) {
			errorHandler(error_, errorNotification, showToast);
		} finally {
			isLoading.value = false;
		}
	};

	return {
		isLoading,
		hasLoaded,
		hasFailed,
		error,
		errorMessage,
		reCaptchaSvg,
		setFallback,
		result,
		callApi,
	};
};

// export endpoints for use in components
export const AI_PAGE_HEATMAP = '/v1/ai/heatmap/page';

export const DOMAIN_WHOIS_DATA = '/v3/domains/whois/';

export const PUBLISH_WITH_GENERATED_URL = '/v3/publish/current';
export const CONNECT_CUSTOM_DOMAIN = '/v3/publish/custom-domain';
export const IS_DOMAIN_CONNECTED = '/v3/domains/is-connected/';
export const DELETE_CUSTOM_DOMAIN = '/v3/publish/custom-domain';
export const TEMPLATE = '/v3/templates';
export const AI_SUBCATEGORIES = '/v1/ai/subcategories';

export const WIZARD_CATEGORY_SUGGESTION = '/v3/wizard/category-suggestion';
export const WIZARD_CATEGORY_SUGGESTION_LOG = '/v3/wizard/category-suggestion-log';
