import axios from 'axios';

export const WEBSITE_IMPORTER_API = `${process.env.VUE_APP_API_URL}/v3/website-importer`;

export const getWebsiteData = async (url, siteId, token, includeSubpages, cancelToken) => {
	const { data } = await axios.post(`${WEBSITE_IMPORTER_API}/website`, {
		url,
		siteId,
		token,
		includeSubpages,
	}, { cancelToken });

	return data;
};

export const importWebsite = async ({
	url,
	siteName,
	templateId,
	multiplePages = true,
}) => {
	const { data } = await axios.post(`${WEBSITE_IMPORTER_API}/import`, {
		url,
		multiplePages,
		siteName,
		templateId,
	});

	return data;
};
