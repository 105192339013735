<template>
	<section
		ref="block"
		class="block"
		:class="{
			'is-selected': isBlockSelected,
			'is-dragging-over': isDraggingOver,
		}"
		:style="variablesString"
	>
		<BlockBackground
			:background="data.background"
			:is-next-to-transparent-header="isNextToTransparentHeader"
		/>
		<Component
			:is="data.type"
			:block-id="id"
			:data="data"
			:is-block-hovered="isBlockSelected"
			:is-next-to-transparent-header="isNextToTransparentHeader"
			:selected-element-id="selectedElementId"
			:active-event-info="activeEventInfo && activeEventInfo.blockId === id ? activeEventInfo : {}"
			:blocks="website.blocks"
			@lock-hovered-block="$emit('lock-hovered-block', $event)"
			@set-edit-control-visibility="$emit('set-edit-control-visibility', $event)"
			@drag-status-change="isDraggingOver = $event"
			@hover-background="isHoveringSelf = $event"
		/>
		<Transition name="fade">
			<BlockHidden
				v-if="isHidden"
				@click="$emit('unhide-block', id, data.slot)"
			/>
		</Transition>
		<div class="block__selection">
			<BlockGridElementInfoOverlay
				:disable-pill-overflow="isFirst"
				:info="blockSelectionInfo"
			/>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex';

import i18n from '@/i18n/setup';
import { useInlineCSSVariables } from '@/use/useInlineCSSVariables';
import { getMapValue } from '@/utils/object';

import BlockHidden from './BlockHidden.vue';

const TITLES_BY_SLOT = {
	footer: i18n.t('common.footer'),
	default: i18n.t('common.section'),
};

export default {
	name: 'Block',
	components: {
		BlockNavigation: () => import('@/components/block-navigation/BlockNavigation.vue'),
		BlockGrid: () => import('@/components/block-grid/BlockGrid.vue'),
		BlockBackground: () => import('@user/components/block/BlockBackground.vue'),
		BlockBlogList: () => import('@/components/block-blog/block-blog-list/BlockBlogList.vue'),
		BlockSlideshow: () => import('@/components/block-slideshow/BlockSlideshow.vue'),
		BlockBlogHeader: () => import('@/components/block-blog/block-blog-header/BlockBlogHeader.vue'),
		BlockEcwidStore: () => import('@/components/block-ecwid-store/BlockEcwidStore.vue'),
		BlockGridElementInfoOverlay: () => import('@/components/block-grid/BlockGridElementInfoOverlay.vue'),
		BlockHidden,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		activeEventInfo: {
			type: Object,
			default: () => ({}),
		},
		isBlockSelected: {
			type: Boolean,
			default: false,
		},
		selectedElementId: {
			type: String,
			default: '',
		},
		isFirst: {
			type: Boolean,
			required: false,
		},
		isNextToTransparentHeader: {
			type: Boolean,
			default: false,
		},
		isHidden: { type: Boolean },
	},
	setup(props) {
		const { variablesString } = useInlineCSSVariables(props);

		// return { variablesString: `${variablesString};margin-top:${-60}px;padding-top:${60}px` };
		return { variablesString };
	},
	data() {
		return {
			isHoveringSelf: false,
			isDraggingOver: false,
		};
	},
	computed: {
		...mapState(['website']),
		isTitleShown() {
			return !this.isBlockSelected || this.isHoveringSelf || this.isDraggingOver;
		},
		sectionTitle() {
			return getMapValue(this.data.slot, TITLES_BY_SLOT);
		},
		blockSelectionInfo() {
			const title = this.isTitleShown ? this.sectionTitle : null;
			const color = this.data.slot === 'footer' ? 'var(--primary)' : 'var(--accent-two)';

			return {
				y1: 0,
				y2: 0,
				x1: 0,
				x2: 0,
				title,
				color,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@user/components/block/Block.scss';

.block {
	$this: &;

	position: relative;
	height: var(--block-height);
	cursor: pointer;

	&__selection {
		position: absolute;
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		z-index: z-index(controls--block-selection);
		display: none;
		pointer-events: none;
	}

	&:not(:last-child) {
		margin-bottom: -1px;
	}

	&:hover,
	&.is-selected,
	&.is-dragging-over {
		#{$this}__selection {
			display: grid;
		}
	}
}
</style>
