<template>
	<div
		class="add-block-button"
		@click="addSection"
	>
		<ZyroButton
			v-if="!isMobileView && !isMobileScreen"
			ref="addSectionButton"
			data-qa="builder-btn-addsection"
			theme="primary"
			color="blue"
			icon-left="plus"
		>
			{{ $t('common.addSection') }}
		</ZyroButton>

		<ZyroButton
			v-else
			ref="addSectionButton"
			data-qa="builder-btn-addsection"
			theme="primary"
			color="blue"
			icon="plus"
			:aria-label="$t('common.addSection')"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

export default {

	props: {
		blockId: {
			type: String,
			default: null,
		},
		blockRef: {
			type: HTMLElement,
			default: null,
		},
	},
	data() {
		return { eventInfo: null };
	},
	computed: {
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		addSection() {
			this.openModal({
				name: 'AddBlockModal',
				settings: {
					previousBlockId: this.blockId,
					previousBlockRef: this.blockRef,
				},
			});
			this.$refs.addSectionButton.$el.blur();
			EventLogApi.logEvent({ eventName: 'user.openBlockLibrary' });
		},
	},
};
</script>

<style lang="scss" scoped>
.add-block-button {
	padding: 10px 15px;
	pointer-events: all;
	cursor: pointer;
}
</style>
