<template>
	<div class="sidebar">
		<div class="sidebar__content">
			<div
				v-for="(actions, actionCategory) in sidebarActions"
				:key="actionCategory"
				class="sidebar__category"
				:class="{ 'sidebar__category--pushed-down': actionCategory === 'settingsActions' }"
			>
				<ZyroSeparator
					v-if="actionCategory === ACTIONS_CATEGORY_SETTINGS"
					class="sidebar__separator"
				/>
				<BuilderSidebarAction
					v-for="(action, index) in actions"
					:key="`${action.title}${index}`"
					:data-qa="`builder-sidebar-action-${index}`"
					v-bind="action"
					@action-click="action.clickHandler"
				/>
				<ZyroSeparator
					v-if="actionCategory === ACTIONS_CATEGORY_BUILDER"
					class="sidebar__separator"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapGetters,
	mapState,
} from 'vuex';

import i18n from '@/i18n/setup';
import {
	ADD_ELEMENT_DRAWER,
	BLOG_DRAWER,
	MULTI_PAGE_DRAWER,
	ADD_ECOMMERCE_STORE_DRAWER,
	USER_STYLES_DRAWER,
	MANAGE_ECOMMERCE_STORE_DRAWER,
	GLOBAL_STYLES_DRAWER,
	AI_WRITER_DRAWER,
	WEBSITE_IMPORTER_DRAWER,
} from '@/store/builder/constants/drawers';
import {
	mapStateGui,
	mapActionsGui,
	TOGGLE_DRAWER,
	TOGGLE_HEATMAP,
	TOGGLE_SIDEBAR,
	CLOSE_DRAWER,
} from '@/store/builder/gui';
import { isDevelopment } from '@/utils/flags';

import BuilderSidebarAction from './BuilderSidebarAction.vue';

const WEBSITE_IMPORTER_COLOR = '#583bb6';
const AI_WRITER_COLOR = '#ffc259';
const AI_HEATMAP_COLOR = '#ff455c';

const ACTIONS_CATEGORY_BUILDER = 'builderActions';
const ACTIONS_CATEGORY_AI = 'aiActions';
const ACTIONS_CATEGORY_SETTINGS = 'settingsActions';

const SUPPORT_LINK = 'https://support.zyro.com/';

export default {
	components: { BuilderSidebarAction },
	setup() {
		return {
			isDevelopment,
			ACTIONS_CATEGORY_BUILDER,
			ACTIONS_CATEGORY_SETTINGS,
			ACTIONS_CATEGORY_AI,
		};
	},
	data() {
		return { activePopup: '' };
	},
	computed: {
		...mapStateGui([
			'isSidebarOpen',
			'isHeatmapOpen',
			'activeDrawer',
		]),
		...mapState('user', ['isUserDesigner']),
		...mapState('blog', ['isBlogEditMode']),
		...mapGetters('onlineStore', ['isSiteWithEcwid']),
		...mapGetters('user', ['isZyroUser']),
		sidebarActions({
			toggleDrawer,
			toggleHeatmap,
		}) {
			/**
			 * In blog edit mode only add element action should be visible
			 */
			if (this.isBlogEditMode) {
				return {
					[ACTIONS_CATEGORY_BUILDER]: [
						{
							isActive: this.activeDrawer === ADD_ELEMENT_DRAWER,
							title: i18n.t('builder.addElementDrawer.title'),
							icon: 'plus-circle',
							clickHandler: () => toggleDrawer(ADD_ELEMENT_DRAWER),
						},
					],
				};
			}

			return {
				[ACTIONS_CATEGORY_BUILDER]: [
					{
						isActive: this.activeDrawer === MULTI_PAGE_DRAWER,
						title: i18n.t('builder.siteMenu.title'),
						icon: 'layers',
						clickHandler: () => toggleDrawer(MULTI_PAGE_DRAWER),
					},
					{
						isActive: this.activeDrawer === ADD_ELEMENT_DRAWER,
						title: i18n.t('builder.addElementDrawer.title'),
						icon: 'plus-circle',
						clickHandler: () => toggleDrawer(ADD_ELEMENT_DRAWER),
					},
					{
						isActive: this.activeDrawer === USER_STYLES_DRAWER,
						title: i18n.t('builder.userStyles.drawerLabel'),
						preventRender: this.isUserDesigner && !this.isDevelopment,
						icon: 'styles-brush',
						clickHandler: () => toggleDrawer(USER_STYLES_DRAWER),
					},
					{
						isActive: this.activeDrawer === GLOBAL_STYLES_DRAWER,
						preventRender: !(this.isZyroUser || this.isUserDesigner) && !this.isDevelopment,
						title: i18n.t('builder.userStyles.drawerLabel'),
						icon: 'styles',
						clickHandler: () => toggleDrawer(GLOBAL_STYLES_DRAWER),
					},
					{
						isActive: this.activeDrawer === BLOG_DRAWER,
						title: i18n.t('builder.blog.blogDrawer.title'),
						icon: 'edit',
						clickHandler: () => toggleDrawer(BLOG_DRAWER),
					},
					{
						isActive: this.activeDrawer === ADD_ECOMMERCE_STORE_DRAWER
							|| this.activeDrawer === MANAGE_ECOMMERCE_STORE_DRAWER,
						title: i18n.t('builder.addEcommerceStoreDrawer.title'),
						icon: 'shopping-cart',
						iconDimensions: '16px',
						clickHandler: () => toggleDrawer((this.isSiteWithEcwid)
							? MANAGE_ECOMMERCE_STORE_DRAWER
							: ADD_ECOMMERCE_STORE_DRAWER),
					},
				],
				[ACTIONS_CATEGORY_AI]: [
					{
						preventRender: this.isUserDesigner,
						isActive: this.activeDrawer === WEBSITE_IMPORTER_DRAWER,
						title: i18n.t('builder.websiteImporter.title'),
						color: WEBSITE_IMPORTER_COLOR,
						icon: 'website-importer',
						clickHandler: () => toggleDrawer(WEBSITE_IMPORTER_DRAWER),
					},
					{
						isActive: this.activeDrawer === AI_WRITER_DRAWER,
						title: i18n.t('builder.AIWriter.title'),
						color: AI_WRITER_COLOR,
						icon: 'app-ai-text-generator',
						clickHandler: () => toggleDrawer(AI_WRITER_DRAWER),
					},
					{
						isActive: this.isHeatmapOpen,
						title: i18n.t('builder.AIHeatmapLabel'),
						color: AI_HEATMAP_COLOR,
						icon: 'app-ai-heatmap',
						clickHandler: toggleHeatmap,
					},
				],
				[ACTIONS_CATEGORY_SETTINGS]: [
					{
						title: i18n.t('builder.sidebarKnowledgeBase'),
						icon: 'question-circle',
						component: 'ZyroButton',
						clickHandler: () => {
							window.open(SUPPORT_LINK, '_blank');
						},
					},
					{
						title: i18n.t('builder.sidebarFeedback.title'),
						isActive: this.activePopup === 'supportPopup',
						component: 'BuilderSidebarFeedbackPopup',
						icon: 'mail',
						clickHandler: () => {
							this.setOpenPopup('supportPopup');
						},
					},
					{
						isActive: this.activePopup === 'builderSettings',
						component: 'BuilderHeaderSettingsPopup',
						title: i18n.t('builder.sidebarWebsiteSettingsLabel'),
						color: 'var(--primary-light)',
						icon: 'settings',
						clickHandler: () => {
							this.setOpenPopup('builderSettings');
						},
					},
				],
			};
		},
	},
	methods: {
		...mapActionsGui({
			toggleDrawer: TOGGLE_DRAWER,
			toggleHeatmap: TOGGLE_HEATMAP,
			toggleSidebar: TOGGLE_SIDEBAR,
			closeDrawer: CLOSE_DRAWER,
		}),
		setOpenPopup(value) {
			if (this.activeDrawer) {
				this.closeDrawer();
			}

			this.activePopup = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
	&__content {
		position: fixed;
		top: $header-height;
		left: 0;
		z-index: z-index(layout--sidebar);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: $side-toolbar-width;
		height: calc(100% - #{$header-height});
		background-color: $light;
		border-top: solid 1px $grey-200;
		border-right: solid 1px $grey-200;
	}

	&__category {
		&--pushed-down {
			margin-top: auto;
		}
	}

	&__separator {
		max-width: 21px;
		height: 2px;
		margin: 30px auto;
	}
}
</style>
