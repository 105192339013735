<template>
	<KeepAlive :include="$options.cachedDrawers">
		<Component
			:is="component"
			v-if="component"
		/>
	</KeepAlive>
</template>

<script>
import AiWriterDrawer from '@/components/builder-drawers/drawers/AiWriterDrawer.vue';
import WebsiteImporterDrawer from '@/components/builder-drawers/drawers/WebsiteImporterDrawer.vue';
import {
	AI_WRITER_DRAWER,
	WEBSITE_IMPORTER_DRAWER,
} from '@/store/builder/constants/drawers';
import { mapStateGui } from '@/store/builder/gui';

const cachedDrawers = [
	WEBSITE_IMPORTER_DRAWER,
	AI_WRITER_DRAWER,
];

export default {
	components: {
		WebsiteImporterDrawer,
		AiWriterDrawer,
	},
	data() {
		return { component: null };
	},
	computed: mapStateGui(['activeDrawer']),
	watch: {
		activeDrawer() {
			// Cached drawers are included to keep-alive and needs to be imported initially
			if (this.$options.cachedDrawers.includes(this.activeDrawer)) {
				this.component = this.activeDrawer;

				return;
			}

			this.component = this.activeDrawer ? () => import(`./drawers/${this.activeDrawer}.vue`) : null;
		},
	},
	cachedDrawers,
};
</script>
