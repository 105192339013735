import {
	computed,
	ref,
} from '@vue/composition-api';

import {
	DIRECTION_LEFT,
	DIRECTION_RIGHT,
	useBlockSlideshow,
} from '@user/components/block-slideshow/use/useBlockSlideshow';

const popupComponentHistory = ref([]);

export const useEditBlockSlideshow = (props, context) => {
	const { $store } = context.root;
	const {
		setActiveSlide,
		setSlidingDirection,
		activeSlidesTransitioning,
		activeSlides,
	} = useBlockSlideshow(props, context);

	const currentEditBlockSlideshowComponent = computed(
		() => popupComponentHistory.value[popupComponentHistory.value.length - 1] ?? null,
	);

	const currentBlockId = computed(() => $store.state.currentBlockId);

	const currentBlock = computed(() => $store.getters.currentBlock);

	const websiteBlocks = computed(() => $store.state.website.blocks);

	const currentSlideshowSlideId = computed(() => activeSlides.value[currentBlockId.value]);

	const currentSlideshowSlidesCount = computed(() => currentBlock.value.slides.length);

	const currentSlideshowSlideName = computed(
		() => currentBlock.value.slides.find((slide) => slide
			.blockId === activeSlides.value[currentBlockId.value])?.name ?? currentBlock.value.slides[0].name,
	);

	const isCurrentSlideshowTransitioning = computed(
		() => activeSlidesTransitioning.value[currentBlockId.value],
	);

	const currentSlideshowActiveSlideIndex = computed(() => {
		const activeSlideIndex = websiteBlocks.value?.[currentBlockId.value]?.slides.findIndex(
			(slide) => activeSlides?.value?.[currentBlockId.value] === slide.blockId,
		);

		return activeSlideIndex === -1 ? 0 : activeSlideIndex;
	});

	const currentSlideshowNextSlideIndex = computed(() => {
		const nextIndex = currentSlideshowActiveSlideIndex.value + 1;

		return nextIndex <= currentSlideshowSlidesCount.value - 1
			? nextIndex
			: 0;
	});

	const currentSlideshowPreviousSlideIndex = computed(() => {
		const previousIndex = currentSlideshowActiveSlideIndex.value - 1;

		return previousIndex >= 0 ? previousIndex : currentSlideshowSlidesCount.value - 1;
	});

	const setCurrentActiveSlideById = (slideId) => {
		setActiveSlide({
			slideshowId: currentBlockId.value,
			slideId,
		});
	};

	const goToNextSlideInCurrentActiveSlideshow = () => {
		if (isCurrentSlideshowTransitioning.value) {
			return;
		}

		setSlidingDirection(DIRECTION_RIGHT);

		const nextSlideId = $store.getters.currentBlock
			.slides[currentSlideshowNextSlideIndex.value].blockId;

		setCurrentActiveSlideById(nextSlideId);
	};

	const goToPreviousSlideInCurrentActiveSlideshow = () => {
		if (isCurrentSlideshowTransitioning.value) {
			return;
		}

		setSlidingDirection(DIRECTION_LEFT);

		const nextSlideId = $store.getters.currentBlock
			.slides[currentSlideshowPreviousSlideIndex.value].blockId;

		setCurrentActiveSlideById(nextSlideId);
	};

	const closeEditBlockSlideshowComponent = () => {
		popupComponentHistory.value.pop();

		if (popupComponentHistory.value.length === 0) {
			context.emit('close');
		}
	};

	const setEditBlockSlideshowComponent = (component) => {
		popupComponentHistory.value.push(component);
	};

	const openSlideBackgroundEdit = (slideId) => {
		setCurrentActiveSlideById(slideId);
		setEditBlockSlideshowComponent('EditBlockSlideshowSlideBackground');
	};

	return {
		currentEditBlockSlideshowComponent,
		currentBlockId,
		websiteBlocks,
		currentSlideshowSlideName,
		currentSlideshowActiveSlideIndex,
		currentSlideshowSlideId,
		setActiveSlide,
		goToNextSlideInCurrentActiveSlideshow,
		goToPreviousSlideInCurrentActiveSlideshow,
		setEditBlockSlideshowComponent,
		openSlideBackgroundEdit,
		closeEditBlockSlideshowComponent,
	};
};
