<template>
	<div
		class="card"
		:style="computedStyles"
	>
		<slot />
	</div>
</template>
<script>
export default {
	props: {
		left: {
			type: String,
			required: true,
		},
		top: {
			type: String,
			required: true,
		},
	},
	computed: {
		computedStyles() {
			return {
				'--popup-frame-left': this.left,
				'--popup-frame-top': this.top,
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.card {
	position: absolute;
	top: var(--popup-frame-top, 0);
	left: var(--popup-frame-left, 0);
	z-index: z-index(controls--popup-card);
	max-width: 400px;
	overflow: hidden;
	user-select: none;
	background: $light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
}
</style>
