<template>
	<div
		ref="heatmap"
		v-click-outside="{
			handler: handleClickOutside,
			events: ['click'],
		}"
		class="heatmap"
		:class="heatmapClass"
		data-qa="builder-heatmap-overlay"
	>
		<Transition
			appear
			name="slide-bottom-to-top"
		>
			<BuilderHeatmapNotification
				v-if="showOverlay"
				class="heatmap__notification"
				data-qa="builder-heatmap-loader-generatingattentionmap"
				:is-loading="isLoading"
				:has-failed="hasFailed"
				:error-message="errorMessage"
				:re-captcha-svg="reCaptchaSvg"
				@set-fallback="setFallback"
				@retry="callHeatmapApi"
			/>
		</Transition>
		<div
			ref="preview"
			v-qa="`builder-heatmap-preview-${showOverlay ? 'off' : 'on'}`"
			class="heatmap__preview"
			:style="previewStyle"
			:class="previewClass"
		/>
		<BuilderHeatmapControls
			class="heatmap__controls"
			:is-preview-enabled="isPreviewEnabled"
			:show-overlay="showOverlay"
			@toggle="isPreviewEnabled = !isPreviewEnabled"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import {
	mapStateGui,
	mapActionsGui,
	CLOSE_HEATMAP,
} from '@/store/builder/gui';
import {
	useApi,
	AI_PAGE_HEATMAP,
} from '@/use/useApi';

import BuilderHeatmapControls from './BuilderHeatmapControls.vue';
import BuilderHeatmapNotification from './BuilderHeatmapNotification.vue';

export default {
	components: {
		BuilderHeatmapNotification,
		BuilderHeatmapControls,
	},
	setup() {
		const {
			isLoading,
			hasFailed,
			errorMessage,
			reCaptchaSvg,
			setFallback,
			result,
			callApi,
		} = useApi();

		return {
			result,
			isLoading,
			hasFailed,
			errorMessage,
			reCaptchaSvg,
			setFallback,
			callApi,
		};
	},
	data() {
		return { isPreviewEnabled: true };
	},
	computed: {
		...mapState(['website']),
		...mapState('pages', ['currentPageId']),
		...mapStateGui(['isHeatmapOpen']),
		showOverlay: ({
			isLoading,
			hasFailed,
		}) => isLoading || hasFailed,
		heatmapClass: ({
			isHeatmapOpen,
			showOverlay,
		}) => ({
			'heatmap--bordered': isHeatmapOpen,
			'heatmap--darkened': showOverlay,
		}),
		previewClass: ({ isPreviewEnabled }) => ({ 'heatmap__preview--enabled': isPreviewEnabled }),
		previewStyle: ({ result }) => result && { backgroundImage: `url(${result})` },
	},
	mounted() {
		this.callHeatmapApi();
	},
	methods: {
		...mapActionsGui({ closeHeatmap: CLOSE_HEATMAP }),
		async callHeatmapApi() {
			const {
				offsetWidth: width,
				offsetHeight: height,
			} = this.$refs.heatmap;

			this.callApi(AI_PAGE_HEATMAP, {
				method: 'post',
				data: {
					/*
					 * before sending `zyroWebsiteData` to the service set the hompageID to currentPageId
					 * as the screenshot is not set up to navigate, it can only render a (home) page
					 */
					zyroWebsiteData: {
						...this.website,
						meta: {
							...this.website.meta,
							homepageId: this.currentPageId,
						},
					},
					mode: 'base64',
					width: Math.round(width),
					height: Math.round(height),
					heatmapOnly: true, // flag for AI service
					withRecaptcha: true,
				},
			});
		},
		handleClickOutside(event) {
			if (!event.target.closest('.heatmap-header')) {
				this.closeHeatmap();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
$border-offset: 5px;

.heatmap {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: z-index(controls--heatmap);
	border: solid 0 $accent-two;
	transition: border-width 200ms $easing-standard, background-color 200ms $easing-standard;

	&--bordered {
		border-width: 0 $border-offset $border-offset;
	}

	&--darkened {
		background-color: rgba($dark, 0.3);
	}

	&__preview {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: z-index(controls--heatmap-preview);
		pointer-events: none;
		background-position: -$border-offset 0;
		opacity: 0;
		transition: opacity 400ms $easing-standard;

		&--enabled {
			opacity: 1;
		}
	}
}
</style>
