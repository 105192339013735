<template>
	<div
		ref="desktopPreview"
		class="builder-root"
	>
		<div class="builder-root__main">
			<BuilderMobileFrame>
				<BuilderPreview v-if="website" />
				<BuilderHeatmap v-if="isHeatmapOpen" />
			</BuilderMobileFrame>
		</div>

		<BuilderDrawers />

		<ContextMenuGridElement />
		<BuilderSidebar class="builder-root__sidebar" />
	</div>
</template>

<script>
import Vue from 'vue';
import {
	mapState,
	mapMutations,
} from 'vuex';

import BuilderDrawers from '@/components/builder-drawers/BuilderDrawers.vue';
import BuilderHeatmap from '@/components/builder-view/components/BuilderHeatmap.vue';
import BuilderMobileFrame from '@/components/builder-view/components/BuilderMobileFrame.vue';
import BuilderSidebar from '@/components/builder-view/components/BuilderSidebar.vue';
import BuilderPreview from '@/components/builder-view/views/BuilderPreview/BuilderPreview.vue';
import ContextMenuGridElement from '@/components/context-menu/ContextMenuGridElement.vue';
import { WWW_REDIRECT_PATHS } from '@/constants';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { useTextEditor } from '@/use/text-editor/useTextEditor';
import { useRedirects } from '@/use/useRedirects';

const { DESIGNER_DRAFTS } = WWW_REDIRECT_PATHS;

export default {
	components: {
		BuilderPreview,
		BuilderSidebar,
		BuilderDrawers,
		BuilderMobileFrame,
		ContextMenuGridElement,
		BuilderHeatmap,
	},
	setup() {
		const { redirectToWWW } = useRedirects();
		const { isTextEditMode } = useTextEditor();

		return {
			redirectToWWW,
			isTextEditMode,
		};
	},
	computed: {
		...mapState([
			'website',
			'published',
		]),
		...mapState('user', [
			'user',
			'isUserDesigner',
			'userHasSites',
			'userHasTemplates',
		]),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
			'isSidebarOpen',
			'isHeatmapOpen',
			'activeModalName',
		]),
	},
	beforeMount() {
		// TODO Move to a mapper
		if (!this.website.forms) {
			Vue.set(this.website, 'forms', {});
		}
	},
	async mounted() {
		window.addEventListener('keydown', this.handleUndoRedo);
		window.addEventListener('keydown', this.handleDeleteElement);
		this.setRef({
			el: 'desktopPreviewRef',
			ref: this.$refs.desktopPreview,
		});

		if (this.isUserDesigner) {
			let agreementAccepted = false;

			if (this.user.profile) {
				agreementAccepted = typeof this.user.profile === 'string' ? JSON.parse(this.user.profile).acceptedAgreement : this.user.profile.acceptedAgreement;
			}

			if (this.website.meta.template !== 'blank' || !agreementAccepted || this.published) {
				this.redirectToWWW({ path: DESIGNER_DRAFTS });
			}

			if ((!window.localStorage.getItem('designerPopUpShowed')
				&& !this.userHasSites)
				|| (!window.localStorage.getItem('designerPopUpShowed') && !this.userHasTemplates)
				|| (!this.userHasSites && !this.userHasTemplates && this.website.meta.isFirstLoad)) {
				this.openModal({ name: 'DesignerStartBuildingModal' });
				window.localStorage.setItem('designerPopUpShowed', true);
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleUndoRedo);
		window.removeEventListener('keydown', this.handleDeleteElement);
	},
	methods: {
		...mapMutations('undoRedo', [
			'undoWebsite',
			'redoWebsite',
		]),
		...mapMutations('gui', ['setRef']),
		...mapActionsGui({ openModal: OPEN_MODAL }),
		handleUndoRedo(event) {
			if (this.isTextEditMode) {
				return;
			}

			// cmd + shift + z or ctrl + shift + z
			if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.which === 90) {
				event.preventDefault();
				this.redoWebsite();

				return;
			}

			// for window cmd + Y
			if (event.ctrlKey && event.which === 89) {
				event.preventDefault();
				this.redoWebsite();

				return;
			}

			// cmd + z or ctrl + z
			if ((event.ctrlKey || event.metaKey) && event.which === 90) {
				event.preventDefault();
				this.undoWebsite();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.builder-root {
	&__main {
		position: relative;
		flex: 1;
		background: $grey-100;
		// Also update BlockGrid.vue isMobileView watcher if timing changes
	}

	&__sidebar {
		margin-left: auto;
	}
}
</style>
