<template>
	<div class="controls">
		<div class="controls__wrapper">
			<ZyroButton
				v-qa="`builder-heatmap-show-${isPreviewEnabled ? 'less' : 'more'}`"
				theme="primary"
				size="xxs"
				color="black"
				icon-dimesnions="16px"
				class="controls__toggle"
				:icon="toggleIcon"
				:disabled="showOverlay"
				@click="$emit('toggle')"
			/>
			<div class="controls__divider" />
			<div class="controls__legend">
				<div class="controls__label z-subheading">
					{{ $t('builder.AIHeatmapLessAttention') }}
				</div>
				<div class="controls__gradient" />
				<div class="controls__label z-subheading">
					{{ $t('builder.AIHeatmapMoreAttention') }}
				</div>
			</div>
			<div class="controls__info">
				<ZyroTooltip
					class="info"
					mode="dark"
					position="right"
					size="small"
					toggle-event="hover"
					is-on-modal
				>
					<template #trigger>
						<ZyroButton
							theme="primary"
							size="xxs"
							color="black"
							icon="help"
							icon-dimesnions="16px"
							class="controls__info-icon"
						/>
					</template>
					<div class="controls__info-content z-body-small">
						{{ $t('builder.AIHeatmapTooltip') }}
					</div>
				</ZyroTooltip>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isPreviewEnabled: {
			type: Boolean,
			required: true,
		},
		showOverlay: {
			type: Boolean,
			required: true,
		},
	},
	computed: { toggleIcon: ({ isPreviewEnabled }) => (isPreviewEnabled ? 'eye-open' : 'eye-closed') },
};
</script>

<style lang="scss" scoped>
.controls {
	position: fixed;
	right: 0;
	bottom: 60px;
	left: 0;
	z-index: z-index(controls--heatmap-controls);
	display: flex;
	justify-content: center;
	pointer-events: none;

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;
		color: $light;
		pointer-events: all;
		background-color: $dark;
		border-radius: $border-radius-small;
	}

	&__toggle {
		margin-right: 4px;
	}

	&__divider {
		width: 1px;
		height: 16px;
		background-color: $light;
	}

	&__legend {
		display: flex;
		align-items: center;
		justify-content: center;
		border-left: solid 1px $light;
	}

	&__label {
		padding: 2px 12px 0;
	}

	&__gradient {
		width: 180px;
		height: 13px;
		margin: 0 4px;
		background: linear-gradient(270deg, #f00 0%, #fcdf49 31.93%, #35ea5d 64.41%, #009ff9 100%);
		border-radius: $border-radius-tiny;
	}

	&__info-content {
		max-width: 30vw;
	}
}
</style>
