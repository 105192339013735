import { ref } from '@vue/composition-api';
import { load } from 'recaptcha-v3';

export const useRecaptcha = () => {
	const token = ref(null);
	const fallback = ref(null);

	const instance = ref(null);
	const isInitializing = ref(false);
	const hasInitialized = ref(false);

	const setFallback = (text) => {
		fallback.value = text;
	};

	const errorHandler = (error) => {
		setFallback(null); // unset fallback
		console.error(error); // additional error logging
		throw error;
	};

	const initialize = async () => {
		isInitializing.value = true;

		try {
			instance.value = await load(process.env.VUE_APP_RE_CAPTCHA_SITE_KEY);
			hasInitialized.value = true;
		} catch (error) {
			// handle error without data when invalid key provided
			errorHandler(error ?? new Error('Invalid VUE_APP_RE_CAPTCHA_SITE_KEY'));
		} finally {
			isInitializing.value = false;
		}
	};

	const getToken = async () => {
		if (!isInitializing.value && !hasInitialized.value) {
			await initialize();
		}

		try {
			token.value = await instance.value.execute();

			return token.value;
		} catch (error) {
			return errorHandler(error);
		}
	};

	return {
		fallback,
		setFallback,
		getToken,
	};
};
