import {
	ref,
	getCurrentInstance,
	computed,
} from '@vue/composition-api';
import {
	Editor,
	Doc,
	Text,
} from 'tiptap';
import {
	Italic,
	Underline,
	Bold,
	HardBreak,
	History,
} from 'tiptap-extensions';

import {
	Heading,
	Paragraph,
	Link,
	Color,
	LetterCase,
} from './custom-extensions/extensions';

const config = {
	useBuiltInExtensions: false,
	extensions: [
		/**
		 * Order defines extension priority.
		 * Fox ex., if extension is the last item, it has highest priority.
		 */
		new Doc(),
		new Text(),
		new History(),
		new Bold(),
		new Italic(),
		new HardBreak(),
		new Paragraph(),
		new Heading(),
		new Color(),
		new Link(),
		new LetterCase(),
		new Underline(),
	],
};

/**
 * EditorsMap saves each textBox editor, since they get reset when we exit builder mode,
 * or add a nex textBox, edit the text and then undo it.
 * If editor was already created, we use it again. If not, we create a new one.
 * localEditor is each textBox local editor, and editor is the currently active editor.
 */
const editorsMap = ref(new Map());
const currentEditorId = ref(null);
const editor = computed(() => editorsMap.value.get(currentEditorId.value) || null);
const isTextEditMode = ref(false);

export const useTextEditor = () => {
	const { commit } = getCurrentInstance()?.$store ?? {};
	const localEditorId = ref(null);
	const localEditor = computed(() => editorsMap.value.get(localEditorId.value));

	const createLocalEditor = (id, content) => {
		if (!editorsMap.value.has(id)) {
			const newEditor = new Editor({
				...config,
				content,
				editable: false,
			});

			editorsMap.value.set(id, newEditor);
		}

		localEditorId.value = id;
	};

	const setCurrentEditor = () => {
		localEditor.value.setOptions({ editable: true });
		currentEditorId.value = localEditorId.value;
		isTextEditMode.value = true;
	};

	const unsetCurrentEditor = () => {
		editor.value.setOptions({ editable: false });
		currentEditorId.value = null;
		isTextEditMode.value = false;
	};

	const saveChanges = (elementId) => {
		commit('setElementData', {
			elementId,
			data: { content: editor.value.getHTML() },
		});
	};

	// Text editor utils
	const setCaretPositionToEnd = () => {
		const endPosition = localEditor.value.state.doc.content.size - 1;

		localEditor.value.setSelection(endPosition, endPosition);
	};

	const insertHTMLToEnd = (content) => {
		editor.value.setContent(`${editor.value.getHTML()}${content}`);
	};

	return {
		editor: computed(() => editor.value),
		localEditor: computed(() => localEditor.value),
		isTextEditMode: computed(() => isTextEditMode.value),
		setCurrentEditor,
		createLocalEditor,
		unsetCurrentEditor,
		saveChanges,
		setCaretPositionToEnd,
		insertHTMLToEnd,
	};
};
