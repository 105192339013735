import axios from 'axios';

export const AI_API = `${process.env.VUE_APP_API_URL}/v1/ai`;

export const getCategories = () => axios.get(`${AI_API}/categories`);

export const getSubcategories = (category) => axios.get(`${AI_API}/subcategories/${category.id}`);

export const getTypes = (subcategory) => axios.get(`${AI_API}/types/${subcategory.id}`);

export const getTexts = ({
	category,
	subcategory,
	type,
}) => axios.get(
	`${AI_API}/texts?
		category=${encodeURIComponent(category)}&
		subcategory=${encodeURIComponent(subcategory)}&
		type=${encodeURIComponent(type)}`,
);
