import { ALLOWED_STYLE_PROPERTIES_FOR_TEXT } from '@/use/text-editor/constants';

export const sanitizeTagStyles = (styles) => {
	if (!styles) {
		return '';
	}

	const allowedStylePropertiesArray = styles.split(';').filter(
		(style) => ALLOWED_STYLE_PROPERTIES_FOR_TEXT.some(
			(allowedProperty) => {
				const propertyName = style.split(':')[0].trim();

				return propertyName === allowedProperty;
			},
		),
	);

	return `${allowedStylePropertiesArray.join(';')};`;
};
