<template>
	<div class="empty-page">
		<h4 class="empty-page__title">
			{{ $t('builder.emptyPage.title') }}
		</h4>
		<p class="empty-page__description">
			{{ $t('builder.emptyPage.description') }}
		</p>
		<AddBlockButton />
	</div>
</template>

<script>
import AddBlockButton from '@/components/builder-controls/control-line/AddBlockButton.vue';

export default {
	name: 'BuilderEmptyPage',
	components: { AddBlockButton },
};
</script>

<style lang="scss" scoped>
.empty-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 292px;

	&__title {
		margin-bottom: 8px;
	}

	&__description {
		margin-bottom: 24px;
	}
}
</style>
