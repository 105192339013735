<template>
	<button
		v-if="isResizeBlockHandleVisible"
		ref="resizeButton"
		class="resize-handle"
		:style="{ '--resize-handle-color': activeEventInfo.outlineColor }"
		@mousedown="startSectionResize"
	>
		<ZyroSvg
			name="chevron-up-small"
			class="resize-handle__icon"
			:class="{ 'resize-handle__icon--disabled': minimumHeightReached }"
		/>
		<ZyroSvg name="chevron-down-small" />
	</button>
</template>

<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

import { getDocumentHeight } from '@/utils/browser';
import { isDesktopSafari } from '@/utils/browserIdentifiers';
import { cloneDeep } from '@/utils/object';
import { parseCSSSides } from '@/utils/parseCSSSides';

const SCROLL_START_THRESHOLD_IN_PX = 100;
const AUTO_SCROLL_INTERVAL_DELAY = 10;
const SCROLL_AMOUNT = 5;

export default {
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
		activeEventInfo: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			eventInfo: null,
			minimumHeightReached: false,
			currentBlockBeforeEdit: null,
			autoResizeInterval: null,
		};
	},
	computed: {
		...mapState(['website']),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		...mapState('gui', ['mobilePreviewRef']),
		blockData() {
			return this.website.blocks[this.blockId];
		},
		distanceBetweenButtonAndHandle() {
			return -(this.eventInfo.y - this.eventInfo.currentMouseMoveClientY);
		},
		shouldScrollUp() {
			const isInTopThreshold = this.eventInfo.currentMouseMoveClientY
			< SCROLL_START_THRESHOLD_IN_PX;
			const isCursorAboveHandle = this.distanceBetweenButtonAndHandle < 0;

			return isInTopThreshold && isCursorAboveHandle;
		},
		shouldScrollDown() {
			const isInBottomThreshold = this.eventInfo.currentMouseMoveClientY
			> document.documentElement.clientHeight - SCROLL_START_THRESHOLD_IN_PX;
			const isCursorBelowHandle = this.distanceBetweenButtonAndHandle > 0;

			return isInBottomThreshold && isCursorBelowHandle;
		},
		shouldScroll() {
			return this.shouldScrollUp || this.shouldScrollDown;
		},
		autoResizeAmount() {
			return this.shouldScrollDown ? SCROLL_AMOUNT : -SCROLL_AMOUNT;
		},
		distanceMoved() {
			return this.distanceBetweenButtonAndHandle + (this.shouldScroll ? this.autoResizeAmount : 0);
		},
		sizeDifference() {
			return this.distanceMoved + this.eventInfo.buttonMovedByScroll;
		},
		calculatedHeight() {
			return this.eventInfo.originalHeightPx + this.sizeDifference;
		},
		currentBlockHeightInPx() {
			return `${Math.max(this.calculatedHeight, this.eventInfo.minimumHeight)}px`;
		},
		isResizeBlockHandleVisible() {
			return (this.blockData.type === 'BlockGrid' || this.blockData.type === 'BlockSlideshow')
				&& !(this.isMobileScreen || this.isMobileView);
		},
		isBlockSlideshow() {
			return this.website.blocks[this.blockId].type === 'BlockSlideshow';
		},
		blockSlideshowComponents() {
			return this.website.blocks[this.blockId].slides
				.flatMap((slide) => this.website.blocks[slide.blockId].components);
		},
	},
	watch: {
		minimumHeightReached(newValue) {
			if (!this.eventInfo) {
				return;
			}

			if (!newValue) {
				this.$emit('update:activeEventInfo', { blockId: this.eventInfo.id });

				return;
			}

			if (this.eventInfo.lastElementId) {
				this.$emit('update:activeEventInfo', {
					blockId: this.eventInfo.id,
					elementId: this.eventInfo.lastElementId,
					outlineColor: 'var(--secondary)',
				});
			} else {
				this.$emit('update:activeEventInfo', {
					blockId: this.eventInfo.id,
					outlineColor: 'var(--secondary)',
				});
			}
		},
	},
	methods: {
		...mapMutations([
			'setBlockData',
			'pushBlockDataToHistory',
		]),
		getNewSectionHeight(buttonMoved) {
			const {
				originalHeight,
				rowHeight,
				rowGap,
			} = this.eventInfo;
			const addedHeight = Math.round(buttonMoved / (rowHeight + rowGap));

			return originalHeight + addedHeight;
		},
		resetAutoScrollInterval() {
			clearInterval(this.autoResizeInterval);
			this.autoResizeInterval = null;
		},

		startSectionResize(event) {
			this.currentBlockBeforeEdit = cloneDeep(this.blockData);

			const parseIntWrapper = (value) => Number.parseInt(value, 10);
			const {
				settings,
				background = {},
			} = this.blockData;
			const components = this.isBlockSlideshow
				? this.blockSlideshowComponents
				: this.blockData.components;
			const { styles } = settings;

			// Gather data that wont change
			const padding = parseCSSSides(styles['block-padding'] || '0px');
			const paddingBottom = parseIntWrapper(padding.bottom);
			const paddingTop = parseIntWrapper(padding.top);
			const rowHeight = parseIntWrapper(styles['row-size']);
			const rowGap = parseIntWrapper(styles['row-gap']);
			const scrollTop = window.pageYOffset;

			let elementsEndAt = null;
			let lastEndPosition = 2;
			let lastElementId = null;
			const existingComponents = components
				.filter((componentId) => componentId in this.website.components);

			if (components.length > 0) {
				elementsEndAt = existingComponents.map((componentId) => {
					const { position } = this.website.components[componentId].settings.styles;

					return {
						endsAt: Number.parseInt(position.split('/')[2], 10),
						componentId,
					};
				});

				lastEndPosition = Math.max(...elementsEndAt.map((element) => element.endsAt), 2);
				lastElementId = elementsEndAt
					.find((element) => element.endsAt === lastEndPosition).componentId;
			} else if (!background.current) {
				lastEndPosition = 9;
			}

			const minimumHeight = (rowHeight + rowGap) * (lastEndPosition - 1) - rowGap
      + paddingBottom + paddingTop;

			const originalHeight = Math.max(
				styles.rows || 0,
				lastEndPosition - 1,
			);

			this.eventInfo = {
				y: event.clientY,
				yPrev: event.clientY,
				id: this.blockId,
				currentMouseMoveClientY: null,
				originalHeightPx: Number.parseInt(this.blockRef.clientHeight, 10),
				originalHeight,
				lastEndPosition,
				lastElementId,
				previousHeight: originalHeight,
				minimumHeight,
				rowHeight,
				rowGap,
				scrollTop,
				buttonMovedByScroll: 0,
			};

			window.addEventListener('mousemove', this.controlSectionResizing);
			window.addEventListener('mouseup', this.stopResizingSection);
			this.$emit('update:activeEventInfo', { blockId: this.eventInfo.id });
			this.$emit('lock-hovered-block', true);
		},
		// If we are in threshold, start scroll-resizing. If not, do a regular resize.
		controlSectionResizing(event) {
			this.eventInfo.currentMouseMoveClientY = event.clientY;
			this.resetAutoScrollInterval();

			if (this.shouldScroll) {
				this.autoResizeInterval = setInterval(() => {
					if (!this.shouldScroll) {
						this.resetAutoScrollInterval();

						return;
					}

					this.resizeSection();
				}, AUTO_SCROLL_INTERVAL_DELAY);

				return;
			}

			this.resizeSection();
		},
		resizeSection() {
			this.animateButton();

			// Resize section
			this.scrollPage({
				shouldScroll: this.shouldScroll,
				scrollByAmount: this.autoResizeAmount,
			});
			this.blockRef.style.setProperty('--block-height', this.currentBlockHeightInPx);

			// Limit minimum height
			this.minimumHeightReached = this.eventInfo.minimumHeight >= this.calculatedHeight;

			const newHeight = this.getNewSectionHeight(this.sizeDifference);

			// Save new height
			if (newHeight === this.eventInfo.previousHeight) {
				return;
			}

			/*
			 *This check is needed because section resize breaks on safari,
			 *update is done on stopResizingSection()
			 */
			if (!isDesktopSafari) {
				this.updateSectionHeight(newHeight);
			}

			this.eventInfo.previousHeight = newHeight;
		},

		stopResizingSection() {
			window.removeEventListener('mousemove', this.controlSectionResizing);
			window.removeEventListener('mouseup', this.stopResizingSection);
			this.resetAutoScrollInterval();

			// Save new height
			this.pushBlockDataToHistory({
				blockId: this.eventInfo.id,
				oldData: this.currentBlockBeforeEdit,
			});

			// If there is fake padding, remove it and scroll to the bottom of the screen
			if (this.mobilePreviewRef.style.paddingBottom) {
				this.mobilePreviewRef.style.setProperty('padding-bottom', '');
				window.scrollTo(0, document.body.scrollHeight);
			}

			// Reset event
			if (this.$refs.resizeButton) {
				this.$refs.resizeButton.style.setProperty('--moved-y', '');
			}

			this.blockRef.style.setProperty('--block-height', '');

			if (isDesktopSafari) {
				this.updateSectionHeight(this.eventInfo.previousHeight);
			}

			this.eventInfo = null;
			this.$emit('update:activeEventInfo', {});
			this.$emit('lock-hovered-block', false);
		},

		animateButton() {
			const transformAmount = this.eventInfo.currentMouseMoveClientY >= this.eventInfo.yPrev
				? SCROLL_AMOUNT : -SCROLL_AMOUNT;

			if (this.$refs.resizeButton) {
				this.$refs.resizeButton.style.setProperty('--moved-y', `${transformAmount}px`);
			}

			if (Math.abs(this.eventInfo.currentMouseMoveClientY - this.eventInfo.yPrev) > SCROLL_AMOUNT) {
				this.eventInfo.yPrev = this.eventInfo.currentMouseMoveClientY;
			}
		},

		updateSectionHeight(newHeight) {
			this.setBlockData({
				blockId: this.eventInfo.id,
				data: { settings: { styles: { rows: Math.max(newHeight, this.eventInfo.lastEndPosition - 1) } } },
			});
		},

		scrollPage({
			shouldScroll,
			scrollByAmount,
		}) {
			if (this.isBrowserWindowAtTheBottomOfTheScreen()) {
				/*
				 * Edge case: when at the bottom of the screen, you can't do a scroll resize and you need a
				 * fixed amount resize mode (which introduces a lot of edge cases and bugs).
				 * To solve this issue, fake padding is added temporarily, so regular auto scroll resizing
				 * could work properly again in the same way it does normally.
				 */
				this.mobilePreviewRef.style.setProperty('padding-bottom', `${getDocumentHeight()}px`);
			}

			if (shouldScroll && !this.minimumHeightReached) {
				window.scrollBy(0, scrollByAmount);
				this.eventInfo.buttonMovedByScroll = -(this.eventInfo.scrollTop - window.pageYOffset);
			}
		},
		// Do a fixed auto resize when resizing up and from the bottom of the screen
		isBrowserWindowAtTheBottomOfTheScreen() {
			const { pageYOffset } = window;
			const {
				body,
				documentElement,
			} = document;
			const {
				scrollTop,
				clientHeight,
			} = documentElement;

			return pageYOffset === 0 ? false
				: Math.max(pageYOffset, scrollTop, body.scrollTop) + clientHeight === getDocumentHeight();
		},
	},
};
</script>

<style lang="scss" scoped>
@include zyro-media($media-grid) {
	.resize-handle {
		&__button {
			display: none;
		}
	}
}

.resize-handle {
	display: flex;
	flex-direction: column;
	padding: 4px 20px;
	color: $light;
	cursor: row-resize;
	background: var(--resize-handle-color, $accent-two);
	border-radius: $border-radius-tiny;
	transition: background 0.2s $easing-standard, transform 0.1s ease-out;
	transform: translateY(var(--moved-y, 0));

	&:hover {
		background: var(--resize-handle-color, $accent-two-hover);
	}

	&__icon {
		margin-bottom: 4px;
		transition: opacity 0.2s linear;

		&--disabled {
			opacity: 0.4;
		}
	}
}
</style>
