<template>
	<div class="notification">
		<div
			v-if="isLoading"
			class="notification__loading"
		>
			<ZyroLoader class="notification__icon" />
			<div
				v-qa="'builder-heatmap-generating'"
				class="notification__label z-subheading"
			>
				{{ $t('builder.AIHeatmapGenerating') }}
			</div>
		</div>
		<div
			v-else-if="hasFailed"
			class="notification__error"
		>
			<template v-if="reCaptchaSvg">
				<div
					class="notification__svg"
					v-html="reCaptchaSvg"
				/>
				<ZyroInput
					class="notification__input"
					@change="handleInputChange"
				/>
			</template>
			<div
				v-else
				class="notification__label z-subheading"
			>
				{{ errorMessage }}
			</div>
			<ZyroButton
				color="plump-purple"
				class="notification__retry"
				theme="primary"
				type="button"
				@click="$emit('retry')"
			>
				{{ $t('builder.AIHeatmapRetry') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			required: true,
		},
		hasFailed: {
			type: Boolean,
			required: true,
		},
		errorMessage: {
			type: String,
			default: null,
		},
		reCaptchaSvg: {
			type: String,
			default: null,
		},
	},
	methods: {
		handleInputChange(event) {
			this.$emit('set-fallback', event.target.value);
		},
	},
};
</script>

<style lang="scss" scoped>
.notification {
	position: fixed;
	top: 30vh;
	left: calc(50% - 110px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 220px;
	padding: 32px;
	text-align: center;
	background-color: $light;

	&__icon {
		--default-size: 56px;
		--default-border-color: transparent;
		--default-weight: 3px;
		--default-color: var(--accent-one);
		--default-border: 3px;
	}

	&__label {
		margin-top: 32px;
	}

	&__retry {
		margin-top: 32px;
	}
}
</style>
