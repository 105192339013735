var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:(_vm.blockId + "-section-control-transition"),staticClass:"section-control",style:(_vm.position),on:{"click":function($event){return _vm.setEditMode(false)}}},[_c('div',{staticClass:"section-control__container",style:({ maxWidth: _vm.maxWidth })},[_c('AddBlockButton',{class:{
				'section-control__button--invisible' :
					!_vm.displayAddBlock || _vm.activeEventInfo.blockId
			},attrs:{"block-id":_vm.blockId,"block-ref":_vm.blockRef}}),_c('div',{staticClass:"section-control__resize-handle"},[_c('ZyroTooltip',{attrs:{"position":"top","toggle-event":"hover","hide-tooltip":_vm.blockIsLocked,"use-portal":false,"content-position":"absolute","forced-position":{
					left: '-39px',
					bottom: '40px',
					'white-space': 'nowrap'
				}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('ResizeButtonHandle',{attrs:{"block-id":_vm.blockId,"block-ref":_vm.blockRef,"active-event-info":_vm.activeEventInfo},on:{"update:activeEventInfo":function($event){_vm.activeEventInfo=$event},"update:active-event-info":function($event){_vm.activeEventInfo=$event},"lock-hovered-block":function($event){_vm.blockIsLocked = $event}}})]},proxy:true}])},[_c('p',{staticClass:"section-control__resize-handle-text z-body-small"},[_vm._v(" "+_vm._s(_vm.$t("builder.sectionControlLine.text"))+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }