<template>
	<div class="text-list">
		<ul class="text-list__items">
			<li
				v-for="(textItem, index) in textArray"
				:key="`ai-text-${index}`"
				class="text-list__item body-small body-small--light"
			>
				<p
					class="text-list__text"
					data-qa="aiwriter-generatedtext"
				>
					{{ textItem.text }}
				</p>
				<ZyroButton
					v-if="showInsertTextButton"
					theme="plain"
					icon-left="duplicate"
					class="text-list__insert-button"
					data-qa="aiwriter-button-inserttext"
					@click="insertTextToTextBox(textItem.text, index)"
				>
					{{ lastInsertedTextIndex === index ? $t('common.inserted') : $t('common.insertText') }}
				</ZyroButton>
				<ZyroButton
					theme="plain"
					icon-left="duplicate"
					data-qa="aiwriter-button-copytext"
					@click="copyTextToClipboard(textItem.text, index)"
				>
					{{ lastCopiedTextIndex === index ? $t('common.copied') : $t('common.copyText') }}
				</ZyroButton>
			</li>
		</ul>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	P_TAG,
	BODY_CLASS,
} from '@/use/text-editor/constants';
import { useTextEditor } from '@/use/text-editor/useTextEditor';

export default {
	props: {
		textArray: {
			type: Array,
			default: () => [],
		},
	},
	setup() {
		const { insertHTMLToEnd } = useTextEditor();

		return { insertHTMLToEnd };
	},
	data() {
		return {
			lastCopiedTextIndex: null,
			lastInsertedTextIndex: null,
		};
	},
	computed: {
		...mapState(['currentElementRef']),
		...mapState('gui', ['isEditMode']),
		...mapGetters(['currentElement']),
		showInsertTextButton() {
			return this.currentElement && this.currentElement.type === 'GridTextBox' && this.isEditMode;
		},
	},
	methods: {
		copyTextToClipboard(text, index) {
			this.lastCopiedTextIndex = index;
			navigator.clipboard.writeText(text);

			EventLogApi.logEvent({
				eventName: 'ai.select-text',
				eventProperties: { text },
			});
		},
		insertTextToTextBox(text, index) {
			const content = `<${P_TAG} class="${BODY_CLASS}">${text}</${P_TAG}>`;

			this.insertHTMLToEnd(content);
			this.lastInsertedTextIndex = index;

			EventLogApi.logEvent({
				eventName: 'ai.select-text',
				eventProperties: { text },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.text-list {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 20px;

	&__text {
		margin-bottom: 30px;
	}

	&__items {
		height: 100%;
		list-style: none;
	}

	&__item {
		padding: 24px 0;
		text-transform: none;
		cursor: text;
		border-top: 1px solid $grey-300;

		&:hover {
			.text-list__button {
				margin-bottom: 0;
				opacity: 1;
			}
		}
	}

	&__insert-button {
		margin-right: 15px;
	}
}
</style>
