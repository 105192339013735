<template>
	<div>
		<ZyroPopup
			v-if="user || $options.isLocalEnv"
			class="settings"
			:show-close-button="false"
			:offset="{
				bottom: -20,
				left: 35
			}"
			type="no-padding"
			@toggle="$emit('toggle', $event)"
			@close="$emit('toggle', false)"
		>
			<template #trigger>
				<ZyroButton
					class="settings__trigger-button"
					data-qa="builder-header-btn-settings"
					color="white"
					icon="settings"
				/>
			</template>
			<ul class="settings__content">
				<li
					v-for="link in linksToDisplay"
					:key="link.title"
					class="settings__list-item"
				>
					<ZyroButton
						v-qa="`builder-header-settingsddl-${link.title}`"
						class="settings__button z-body-small"
						:to="{ name: link.to }"
					>
						{{ link.title }}
					</ZyroButton>
				</li>
			</ul>
			<div
				class="settings__lang-switcher"
				@mouseenter="toggleList"
				@mouseleave="toggleList"
			>
				<ZyroButton
					class="z-body-small"
					data-qa="builder-header-settingsddl-language"
					icon-right="globe"
				>
					{{ $t('common.language') }}
				</ZyroButton>
				<span class="settings__lang-switcher-text z-body-small">{{ currentLanguage }}</span>
				<Transition name="fade">
					<LanguageSwitcher
						v-if="isListOpen"
						class="settings__languages-list"
					/>
				</Transition>
			</div>
		</ZyroPopup>
		<div
			v-else
			class="settings"
		>
			<ZyroPopup
				:offset="{
					bottom: -20,
					left: 35
				}"
				type="no-padding"
				:show-close-button="false"
				@toggle="$emit('toggle', $event)"
				@close="$emit('toggle', false)"
			>
				<template slot="trigger">
					<ZyroButton
						color="white"
						icon="globe"
					/>
				</template>
				<LanguageSwitcher />
			</ZyroPopup>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import LanguageSwitcher from '@/components/builder-view/components/LanguageSwitcher.vue';
import i18n from '@/i18n/setup';
import {
	GENERAL_SETTINGS_ROUTE,
	DOMAIN_SETTINGS_ROUTE,
	INTEGRATIONS_SETTINGS_ROUTE,
	ONLINE_STORE_SETTINGS_ROUTE,
	SUBSCRIPTION_SETTINGS_ROUTE,
	SEO_SETTINGS_ROUTE,
	FORMS_SETTINGS_ROUTE,
} from '@/router';
import { getLocale } from '@/utils/i18n/supportedLocales';

const LINKS = [
	{
		title: i18n.t('siteSettings.nav.general'),
		to: GENERAL_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.domain'),
		to: DOMAIN_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.integrations'),
		to: INTEGRATIONS_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.online-store'),
		to: ONLINE_STORE_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.subscription'),
		to: SUBSCRIPTION_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.seo'),
		to: SEO_SETTINGS_ROUTE,
	},
	{
		title: i18n.t('siteSettings.nav.forms'),
		to: FORMS_SETTINGS_ROUTE,
	},
];

const CURATED_LINKS = [
	{
		title: i18n.t('siteSettings.nav.online-store'),
		to: ONLINE_STORE_SETTINGS_ROUTE,
	},
];

const isLocalEnvironment = process.env.VUE_APP_ENV_NAME === 'local';

export default {
	components: { LanguageSwitcher },
	data() {
		return { isListOpen: false };
	},
	computed: {
		...mapState(['websiteId']),
		...mapState('user', [
			'user',
			'isUserDesigner',
		]),
		currentLanguage() {
			return getLocale(this.$i18n.locale).language;
		},
		linksToDisplay() {
			return this.isUserDesigner ? CURATED_LINKS : LINKS;
		},
	},
	methods: {
		toggleList() {
			this.isListOpen = !this.isListOpen;
		},
	},
	isLocalEnv: isLocalEnvironment,
};
</script>

<style scoped lang="scss">
$popup-width: 201px;

.settings {
	$this: &;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0;

	&__content {
		box-sizing: border-box;
		width: $popup-width;
		list-style: none;
	}

	&__list-item {
		&:first-child {
			#{$this}__button {
				padding-top: 18px;
			}
		}

		&:last-child {
			#{$this}__button {
				padding-bottom: 18px;
			}
		}
	}

	&__button {
		justify-content: flex-start;
		width: 100%;
		padding: 10px 12px;

		&:not(.s) {
			min-width: 24px;
		}
	}

	&__trigger-button {
		padding: 16px;
	}

	&__languages-list {
		position: absolute;
		bottom: 0;
		left: $popup-width;
	}

	&__lang-switcher {
		padding: 18px 12px;
		border-top: 1px solid $grey-400;
	}

	&__lang-switcher-text {
		padding-left: 18px;
		color: $grey-600;
	}
}

</style>
