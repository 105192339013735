<template>
	<div class="preview">
		<main
			v-if="website"
			ref="mobilePreview"
			class="preview__content"
		>
			<BlockHeader
				v-if="isNavigationVisible"
				id="navigation"
				ref="navigation-ref"
				data-qa="builder-section-navigation"
				is-preview-navigation
				:data="website.blocks.navigation"
				:is-block-selected="'navigation' === selectedBlockId"
				:active-event-info="activeEventInfo"
				:selected-element-id="currentElementId"
				@set-edit-control-visibility="editControlsVisible = $event"
				@click.native="handleBlockSelect('navigation')"
				@mouseover.native="handleBlockHover('navigation')"
			/>
			<BuilderEmptyPage v-if="isEmptyPageBlockShown" />
			<Block
				v-for="(blockId, index) of allBlocks"
				:id="blockId"
				:key="blockId"
				:ref="`${blockId}-ref`"
				v-qa="`builder-section-${index}`"
				:data="website.blocks[blockId]"
				:is-block-selected="blockId === selectedBlockId"
				:is-hidden="isBlockHidden(blockId)"
				class="preview__block"
				:active-event-info="activeEventInfo"
				:selected-element-id="currentElementId"
				:is-first="!isNavigationVisible && index === 0"
				:is-next-to-transparent-header="index === 0 && website.blocks.navigation.background.isTransparent"
				@unhide-block="onToggleLinkedBlock('footer', false, false)"
				@lock-hovered-block="lockHoveredBlock = $event"
				@set-edit-control-visibility="editControlsVisible = $event"
				@click.native="handleBlockSelect(blockId)"
				@mouseover.native="handleBlockHover(blockId)"
			/>

			<EditControls
				v-if="editControlsVisible && selectedBlockRef && selectedBlockId"
				:block-ref="selectedBlockRef"
				:block-id="selectedBlockId"
			/>
			<div
				v-if="editControlsVisible"
				class="preview__edit-block"
			>
				<Transition name="fade-in">
					<PositionBlockEditor
						v-if="!currentElementId && selectedBlockRef
							&& (selectedBlockId !== currentBlockId || !blockEditorOpen)
							&& !isTransitioning
						"
						component="EditBlockButton"
						class="preview__edit-block-button"
						:block-ref="selectedBlockRef"
						:block-id="selectedBlockId"
						:block-type="selectedBlockType"
						:block-slot="selectedBlockSlot || ''"
						:is-linked-block-hidden="currentPage[`${selectedBlockSlot}SlotIsHidden`]"
						:is-moving-block-up-allowed="isMovingBlockUpAllowed(selectedBlockId)"
						:is-moving-block-down-allowed="isMovingBlockDownAllowed(selectedBlockId)"
						@move-block-up="onMoveBlockUp(selectedBlockId)"
						@move-block-down="onMoveBlockDown(selectedBlockId)"
						@edit-block="onEditBlock"
						@remove-block="onRemoveBlock"
						@duplicate-block="onDuplicateBlock"
						@toggle-linked-block="onToggleLinkedBlock"
						@sort-block-components="onSortBlockComponents"
					/>
				</Transition>

				<Transition name="fade-in">
					<!-- if there is no middleware on v-click-outside, actions in image modal
					close the popup  -->
					<PositionBlockEditor
						v-if="blockEditorOpen"
						v-click-outside="{
							handler: closeBlockEditor,
							middleware: (e) => !e.target.closest('.app-portal'),
							events: ['mousedown'],
						}"
						:block-ref="currentBlockRef"
						:block-id="selectedBlockId"
						:block-type="selectedBlockType"
						:start-tab-id="defaultBlockEditTab"
						component="EditBlockPopup"
						:starting-popup-component="editBlockStartingPopupComponent"
						@close="closeBlockEditor"
					/>
				</Transition>
			</div>

			<Transition
				name="fade-in--quick"
			>
				<PositionBlockEditor
					v-if="(hoveredBlock)
						&& !isTransitioning"
					:display-add-block="selectedBlockSlot !== 'footer'
						&& hoveredBlock.slot !== 'footer'"
					:block-ref="hoveredBlock.ref"
					:block-id="hoveredBlock.id"
					:block-type="hoveredBlock.type"
					component="SectionControlLine"
					:active-event-info.sync="activeEventInfo"
					@lock-hovered-block="lockHoveredBlock = $event"
				/>
			</Transition>
		</main>
	</div>
</template>

<script>
import {
	mapGetters,
	mapState,
	mapMutations,
	mapActions,
} from 'vuex';

import Block from '@/components/block/Block.vue';
import EditControls from '@/components/builder-controls/EditControls.vue';
import PositionBlockEditor from '@/components/builder-controls/PositionBlockEditor.vue';
import BuilderEmptyPage from '@/components/builder-view/components/BuilderEmptyPage.vue';
import { EventBus } from '@/eventBus';
import {
	moveOneLeft,
	moveOneRight,
} from '@/utils/array';
import BlockHeader from '@user/components/block/BlockHeader.vue';

export default {
	name: 'BuilderPreview',
	components: {
		BuilderEmptyPage,
		Block,
		EditControls,
		PositionBlockEditor,
		BlockHeader,
	},
	data() {
		return {
			blockEditorOpen: false,
			selectedBlockRef: '',
			selectedBlockId: '',
			selectedBlockType: '',
			selectedBlockSlot: '',
			hoveredBlock: null,
			defaultBlockEditTab: '',
			lockHoveredBlock: false,
			isTransitioning: false,
			activeEventInfo: {},
			editControlsVisible: true,
			editBlockStartingPopupComponent: null,
		};
	},
	computed: {
		...mapState([
			'website',
			'currentElementBoxRef',
			'currentBlockId',
			'currentBlockRef',
			'currentElementId',
		]),
		...mapGetters('gui', ['isNavigationVisible']),
		...mapGetters(['currentBlock']),
		...mapGetters('pages', [
			'currentPage',
			'isEmptyPageBlockShown',
		]),
		pageBlocks() {
			if (!this.currentPage) {
				this.changePage();
			}

			return this.currentPage.blocks;
		},
		pageBlocksSlotFooter() {
			const footerBlock = Object.keys(this.website.blocks)
				.find((blockId) => this.website.blocks[blockId].slot === 'footer');

			return footerBlock ? [footerBlock] : [];
		},
		allBlocks() {
			return [
				...this.pageBlocks,
				...this.pageBlocksSlotFooter,
			];
		},
	},
	watch: {
		currentPage() {
			this.resetHoveredBlock();
		},
		blockEditorOpen(newValue) {
			this.activeEventInfo = { blockId: newValue ? this.selectedBlockId : null };
		},
		selectedBlockId() {
			this.unsetCurrentElement();
		},
	},
	mounted() {
		this.setRef({
			el: 'mobilePreviewRef',
			ref: this.$refs.mobilePreview,
		});

		EventBus.$on('before-undo', () => {
			this.isTransitioning = true;
		});

		EventBus.$on('after-undo', async () => {
			await this.$nextTick;
			this.isTransitioning = false;
		});
		this.setEditMode(false);
	},
	methods: {
		...mapMutations('gui', ['setRef']),
		...mapMutations([
			'setCurrentBlock',
			'setBlockData',
			'unsetCurrentElement',
		]),
		...mapActions(['duplicateBlock']),
		...mapActions('pages', [
			'changePage',
			'setPageData',
		]),
		...mapActions('gui', ['setEditMode']),
		...mapActions({ removeBlockMutation: 'removeBlock' }),
		getBlockRef(blockId) {
			const nonDynamicReferences = [
				'navigation',
				'footer',
			];

			return nonDynamicReferences.includes(blockId)
				? this.$refs[`${blockId}-ref`]?.$el : this.$refs[`${blockId}-ref`][0]?.$el;
		},
		handleBlockHover(blockId) {
			if (this.lockHoveredBlock) {
				return;
			}

			this.hoveredBlock = {
				id: blockId,
				ref: this.getBlockRef(blockId),
				type: this.website.blocks[blockId].type,
				slot: this.website.blocks[blockId].slot,
			};
		},
		handleBlockSelect(blockId) {
			if (this.lockHoveredBlock) {
				return;
			}

			this.selectedBlockId = blockId;
			this.selectedBlockRef = this.getBlockRef(blockId);
			this.setCurrentBlock({
				blockId: this.selectedBlockId,
				blockRef: this.selectedBlockRef,
			});
			this.selectedBlockType = this.website.blocks[blockId].type;
			this.selectedBlockSlot = this.website.blocks[blockId].slot;
		},
		resetHoveredBlock() {
			this.selectedBlockId = null;
			this.selectedBlockRef = null;
			this.selectedBlockType = null;
			this.selectedBlockSlot = null;
			this.hoveredBlock = null;
		},
		closeBlockEditor() {
			this.blockEditorOpen = false;
			this.defaultBlockEditTab = '';
		},
		onEditBlock(options) {
			this.editBlockStartingPopupComponent = options?.startingPopupComponent || null;
			if (this.selectedBlockId !== this.currentBlockId) {
				this.setHoveredBlockAsCurrent();
				this.blockEditorOpen = true;
			} else {
				this.blockEditorOpen = !this.blockEditorOpen;
			}

			this.setEditMode(false);
			this.defaultBlockEditTab = options?.tabId || '';
		},
		setHoveredBlockAsCurrent() {
			this.setCurrentBlock({
				blockId: this.selectedBlockId,
				blockRef: this.selectedBlockRef,
			});
		},
		async onDuplicateBlock() {
			// Set current block and close editing popup
			this.setHoveredBlockAsCurrent();
			this.setEditMode(false);
			this.closeBlockEditor();
			this.duplicateBlock({ addToPage: true });
			await this.$nextTick;
			// Hide controls
			this.resetHoveredBlock();
			// Scroll to new block
			this.currentBlockRef.nextSibling.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		},
		onToggleLinkedBlock(slot, onAllPages, hide) {
			const slotProperty = `${slot}SlotIsHidden`;

			if (onAllPages) {
				Object.keys(this.website.pages).forEach((pageId) => {
					this.setPageData({
						type: 'default',
						payload: {
							data: { [slotProperty]: hide },
							pageId,
						},
					});
				});
			} else {
				const oldValue = this.currentPage[slotProperty];

				this.setPageData({
					type: 'default',
					payload: { data: { [slotProperty]: !oldValue } },
				});
			}
		},
		isBlockHidden(blockId) {
			return this.website.blocks[blockId].isHidden
				|| (this.website.blocks[blockId].slot === 'footer' && this.currentPage.footerSlotIsHidden);
		},
		isMovingBlockUpAllowed(blockId) {
			const blockIndex = this.pageBlocks.indexOf(blockId);

			return blockIndex !== 0 && blockIndex !== -1;
		},
		isMovingBlockDownAllowed(blockId) {
			const blockIndex = this.pageBlocks.indexOf(blockId);

			return blockIndex !== this.pageBlocks.length - 1 && blockIndex !== -1;
		},
		setPageBlocks(blocks) {
			this.setPageData({
				type: 'default',
				payload: { data: { blocks } },
			});
		},
		onMoveBlockUp(blockId) {
			this.setPageBlocks(moveOneLeft(this.pageBlocks, blockId));
			this.onAfterSectionReorder(blockId);
		},
		onMoveBlockDown(blockId) {
			this.setPageBlocks(moveOneRight(this.pageBlocks, blockId));
			this.onAfterSectionReorder(blockId);
		},
		async onAfterSectionReorder(blockId) {
			// wait till vue sets section order and renders it
			await this.$nextTick();
			this.handleBlockSelect(blockId);
			// Wait till block is reselected
			await this.$nextTick();
			// yOffset is arbitary, needed so we can see both blocks in view
			const yOffset = -150;
			const top = this.selectedBlockRef.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({
				top,
				behavior: 'smooth',
			});
		},
		onSortBlockComponents() {
			this.setHoveredBlockAsCurrent();
			const positionsAndIds = this.currentBlock.components.map((componentId) => {
				const { position } = this.website.components[componentId].settings.styles;

				return {
					position,
					componentId,
				};
			});

			/*
			 * grid position:
			 * top/left/bottom/right
			 * sorts by right
			 * then sorts by bottom
			 */
			positionsAndIds.sort((position, position2) => {
				const splitPosition = position.position.split('/').map((singlePosition) => Number.parseInt(singlePosition, 10));
				const splitPosition2 = position2.position.split('/').map((singlePosition) => Number.parseInt(singlePosition, 10));

				if (splitPosition[2] > splitPosition2[2]) {
					return 1;
				}

				if (splitPosition[2] < splitPosition2[2]) {
					return -1;
				}

				if (splitPosition[3] > splitPosition2[3]) {
					return 1;
				}

				if (splitPosition[3] < splitPosition2[3]) {
					return -1;
				}

				return 0;
			});
			const sortedIds = positionsAndIds.map((position) => position.componentId);

			this.currentBlock.components = sortedIds;
		},
		onRemoveBlock() {
			// Set current block and close editing popup
			const blockIdBeforeRemove = this.selectedBlockId;

			this.setHoveredBlockAsCurrent();
			this.setEditMode(false);
			this.closeBlockEditor();
			this.isTransitioning = true;
			const transitionDuration = 400;

			this.currentBlockRef.style.setProperty('transition', `height ${transitionDuration}ms ease-in-out`);
			this.currentBlockRef.querySelector('.background').style.setProperty('transition', `margin ${transitionDuration}ms ease-in-out`);
			this.currentBlockRef.style.setProperty('height', `${this.currentBlockRef.clientHeight}px`);
			// Wait for transition style to be set
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.currentBlockRef.style.setProperty('height', '0px');
					this.currentBlockRef.querySelector('.background').style.setProperty('margin-top', '0px');
				});
			});
			// 'transitionend' event dont always fire, use timeout
			setTimeout(() => {
				this.removeBlockMutation({ blockId: this.currentBlockId });
				this.isTransitioning = false;
				/*
				 * Fixes an issue where when all blocks are removed
				 * and hovered block is not updated but button still shows up with wrong position
				 */
				if (blockIdBeforeRemove === this.selectedBlockId) {
					this.resetHoveredBlock();
				}
			}, transitionDuration);
		},
	},
};
</script>

<style lang="scss" scoped>
.preview {
	/*
	* Without high min-height edit popup
	* is not always visible when users site has low height
	*/
	min-height: calc(100vh - #{$header-height});

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		user-select: none;
	}

	&__block {
		&:last-of-type {
			margin-bottom: 32px;
		}
	}

	&__edit-block {
		z-index: z-index(controls--edit-block);
	}
}

.fade-in {
	transform-origin: 50% -50px;

	&--quick {
		&-enter-active,
		&-leave-active {
			transition: transform 0.15s, opacity 0.1s;
		}

		&-enter,
		&-leave-to {
			opacity: 0;
			transform: rotateX(-10deg) scaleY(0.98) scaleX(0.99);
		}
	}

	&-enter-active,
	&-leave-active {
		transition: transform 0.25s, opacity 0.2s;
	}

	&-enter,
	&-leave-to {
		opacity: 0;
		transform: rotateX(-10deg) scaleY(0.98) scaleX(0.99);
	}
}
</style>
