<template>
	<ZyroDrawer
		:show-close-button="!hideCloseButton"
		data-qa="builder-sidebar-websiteimporter"
	>
		<div class="importer">
			<KeepAlive>
				<WebsiteImporterInput
					v-if="currentView === 'input'"
					:imported-content-url="importedContentURL"
					:imported-with-subpages="importedWithSubpages"
					:loading="loading"
					@scan-website="getData"
					@cancel-scan-website="cancelGetData"
					@go-back="currentView = 'content'"
				/>
				<WebsiteImporterContent
					v-else-if="currentView === 'content'"
					:content="websiteData"
					:content-url="importedContentURL"
					@edit-url="currentView = 'input'"
				/>
			</KeepAlive>
		</div>
	</ZyroDrawer>
</template>

<script>
import axios from 'axios';
import saveState from 'vue-save-state';
import {
	mapState,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { getWebsiteData } from '@/api/WebsiteImporterApi';
import WebsiteImporterContent from '@/components/builder-controls/website-importer/WebsiteImporterContent.vue';
import WebsiteImporterInput from '@/components/builder-controls/website-importer/WebsiteImporterInput.vue';
import { WEBSITE_IMPORTER_DRAWER } from '@/store/builder/constants/drawers';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';

const REQUEST_CANCEL_MESSAGE = 'Request canceled';
// These properties will be saved in localstorage and restored if exists
const SAVED_STATE_PROPERTIES = [
	'websiteData',
	'currentView',
	'importedContentURL',
	'importedWithSubpages',
];

export default {
	components: {
		WebsiteImporterInput,
		WebsiteImporterContent,
	},
	mixins: [saveState],
	data() {
		return {
			currentView: 'input',
			importedContentURL: '',
			importedWithSubpages: false,
			websiteData: null,
			loading: false,
			requestCancelSource: null,
		};
	},
	computed: {
		...mapState(['websiteId']),
		...mapState('user', ['user']),
		hideCloseButton() {
			return this.importedContentURL && this.currentView === 'input';
		},
	},
	activated() {
		EventLogApi.logEvent({ eventName: 'builder.website_importer.open_sidebar' });
	},
	methods: {
		...mapActionsNotifications({ notify: NOTIFY }),
		...mapMutations(['setWebsite']),
		getSaveStateConfig() {
			return {
				cacheKey: `${WEBSITE_IMPORTER_DRAWER}_${this.websiteId}`,
				saveProperties: SAVED_STATE_PROPERTIES,
			};
		},
		async getData({
			url,
			includeSubpages,
		}) {
			try {
				this.loading = true;
				const { CancelToken } = axios;

				this.requestCancelSource = CancelToken.source();
				await this.$recaptchaLoaded();
				const token = await this.$recaptcha();
				const websiteData = await getWebsiteData(
					url, this.websiteId, token, includeSubpages, this.requestCancelSource.token,
				);

				this.importedContentURL = url;
				this.importedWithSubpages = includeSubpages;
				this.websiteData = websiteData;
				this.currentView = 'content';
				// Hotjar identify call that user used website-importer
				window.hj('identify', this.user.id, { usedWebsiteImporter: true });
				EventLogApi.logEvent({ eventName: 'builder.website_importer.scan_website' });
			} catch (error) {
				if (error.message !== REQUEST_CANCEL_MESSAGE) {
					this.notify({
						message: 'Error while importing a website. Try again.',
						origin: 'AiWebsiteImporter',
					});
				}
			} finally {
				this.loading = false;
			}
		},
		cancelGetData() {
			this.requestCancelSource.cancel(REQUEST_CANCEL_MESSAGE);
		},
	},
	SAVED_STATE_PROPERTIES,
};
</script>

<style lang="scss" scoped>
.importer {
	position: relative;
	display: flex;
	height: 100%;
	padding: 24px 24px 0 24px;
	overflow: hidden;
}
</style>
