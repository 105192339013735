<template>
	<div>
		<ZyroPopup
			class="feedback"
			:show-close-button="false"
			:offset="{
				bottom: -20,
				left: 35
			}"
			type="no-padding"
			@toggle="$emit('toggle', $event)"
			@close="$emit('toggle', false)"
		>
			<template #trigger>
				<ZyroButton
					class="feedback__trigger-button"
					data-qa="builder-sidebar-btn-feedback"
					color="white"
					icon="mail"
				/>
			</template>
			<ul class="feedback__content">
				<li
					v-if="!isMobileScreen"
					class="feedback__list-item"
				>
					<ZyroButton
						class="feedback__button z-body-small"
						data-qa="builder-sidebar-btn-sendfeedback"
						:title="$t('builder.sidebarFeedback.sendFeedback')"
						@click="setNpsVisibility(true)"
					>
						{{ $t('builder.sidebarFeedback.sendFeedback') }}
					</ZyroButton>
				</li>
				<li class="feedback__list-item">
					<ZyroButton
						:title="$t('builder.sidebarFeedback.requestFeature')"
						data-qa="builder-sidebar-btn-requestfeature"
						class="feedback__button z-body-small"
						:href="$options.ROADMAP_LINK"
						target="_blank"
					>
						{{ $t('builder.sidebarFeedback.requestFeature') }}
					</ZyroButton>
				</li>
			</ul>
		</ZyroPopup>
	</div>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';

const ROADMAP_LINK = 'https://roadmap.zyro.com/tabs/1-new-ideas/submit-idea';

export default {
	ROADMAP_LINK,
	computed: mapState('gui', ['isMobileScreen']),
	methods: mapActions('nps', ['setNpsVisibility']),
};
</script>

<style scoped lang="scss">
$popup-width: 201px;

.feedback {
	$this: &;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0;

	&__content {
		box-sizing: border-box;
		width: $popup-width;
		list-style: none;
	}

	&__list-item {
		&:first-child {
			#{$this}__button {
				padding-top: 18px;
			}
		}

		&:last-child {
			#{$this}__button {
				padding-bottom: 18px;
			}
		}
	}

	&__button {
		justify-content: flex-start;
		width: 100%;
		padding: 10px 12px;

		&:not(.s) {
			min-width: 24px;
		}
	}

	&__trigger-button {
		padding: 16px;
	}
}

</style>
