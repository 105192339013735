<template>
	<Component
		:is="component"
		ref="componentRoot"
		:position="position"
		v-bind="{
			...$attrs,
			...$props
		}"
		v-on="$listeners"
		@hook:mounted="computeBlockEditorPosition(blockRef)"
	/>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import EditBlockButton from '@/components/builder-controls/EditBlockButton.vue';
import EditBlockPopup from '@/components/builder-controls/EditBlockPopup.vue';
import SectionControlLine from '@/components/builder-controls/control-line/SectionControlLine.vue';
import position from '@/utils/positioning';

export default {
	components: {
		SectionControlLine,
		EditBlockButton,
		EditBlockPopup,
	},
	inheritAttrs: false,
	props: {
		component: {
			type: String,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
		blockType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			position: {},
			resizeObserver: null,
		};
	},
	computed: {
		...mapState('gui', [
			'mobilePreviewRef',
			'desktopPreviewRef',
			'isMobileView',
			'isMobileScreen',
		]),
		...mapGetters(['currentBlock']),
	},
	watch: {
		blockRef(newBlock) {
			this.computeBlockEditorPosition(newBlock);
		},
	},
	mounted() {
		this.resizeObserver = new ResizeObserver(() => this.computeBlockEditorPosition(this.blockRef));

		// Mobile preview = scrollable section container
		this.resizeObserver.observe(this.mobilePreviewRef);

		this.computeBlockEditorPosition(this.blockRef);
	},
	beforeDestroy() {
		this.resizeObserver.unobserve(this.mobilePreviewRef);
		this.resizeObserver = null;
	},
	methods: {
		computeBlockEditorPosition(element) {
			if (!element) {
				return;
			}

			const elementOffset = position.getElementOffset(
				element,
				this.isMobileView ? this.mobilePreviewRef : this.desktopPreviewRef,
			);

			const offsetRight = 80;
			const offsetTop = 15;
			const minimumOffsetTop = 60;

			const positionToBottomOfSection = () => {
				const top = this.isMobileView
					? `${elementOffset.top + elementOffset.height - this.$refs.componentRoot.$el.clientHeight}px`
					/*
					 * If the header's height is less than minimumOffsetTop, we will not move
					 * the edit controls any higher otherwise it hides behind the builder header
					 */
					: `${elementOffset.top + (elementOffset.height > minimumOffsetTop
						? elementOffset.height : minimumOffsetTop) - offsetTop - this.$refs.componentRoot.$el.clientHeight}px`;
				const right = `${elementOffset.right + offsetRight}px`;

				this.position = {
					top,
					right,
				};
			};

			const positionToTopOfSection = () => {
				const mobileTopValue = elementOffset.top - offsetTop - 35; // 35px is row height
				const top = this.isMobileView && mobileTopValue > 0
					? `${mobileTopValue}px`
					: `${elementOffset.top + offsetTop}px`;
				const right = this.isMobileView ? 0 : `${elementOffset.right + offsetRight}px`;
				const left = this.isMobileView ? 0 : 'unset';

				this.position = {
					top,
					right,
					left,
				};
			};

			// Tip: use arrows on the left to collapse case blocks for better readability
			switch (this.component) {
			case 'SectionControlLine': {
				const top = `${elementOffset.top + elementOffset.height - 1 - this.$refs.componentRoot.$el.clientHeight / 2}px`;

				this.position = { top };
				break;
			}

			case 'EditBlockButton': {
				switch (this.blockType) {
				case 'BlockNavigation': {
					if (this.isMobileView || this.isMobileScreen) {
						positionToTopOfSection();
					} else {
						positionToBottomOfSection();
					}

					break;
				}

				default: {
					positionToTopOfSection();
					break;
				}
				}

				break;
			}

			case 'EditBlockPopup': {
				if (this.isMobileView) {
					/**
					 * I know this is horrible code I added,
					 * but there is so much logic in here and its sooo easy to break things
					 * that this needs a complete refactor.
					 * This code aligns popups to right side of mobile preview
					 * so you can actually see what you're editing
					 */
					positionToTopOfSection();
					this.position.left = `${this.mobilePreviewRef.clientWidth + offsetRight / 2}px`;
					break;
				}

				const topBlockPostion = {
					top: `${elementOffset.top + elementOffset.height + 14}px`,
					right: `${elementOffset.right + this.isMobileView ? 0 : offsetRight}px`,
				};

				switch (this.currentBlock.type) {
				case 'BlockNavigation': {
					this.position = {
						top: topBlockPostion.top,
						right: topBlockPostion.right,
					};
					break;
				}

				case 'BlockBlogHeader': {
					this.position = {
						top: topBlockPostion.top,
						right: topBlockPostion.right,
					};
					break;
				}

				default: {
					const popupHeight = 580;
					const isBlockInPageBottom = document.body.scrollHeight - elementOffset.top < popupHeight;
					const top = isBlockInPageBottom ? `${document.body.scrollHeight - popupHeight}px` : `${elementOffset.top + 15}px`;
					const right = `${elementOffset.right + this.isMobileView ? 0 : offsetRight}px`;

					this.position = {
						top,
						right,
					};
					break;
				}
				}

				break;
			}

			default: {
				this.position = {
					top: 0,
					right: 0,
				};
			}
			}
		},
	},
};
</script>
