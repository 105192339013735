<template>
	<div
		v-if="!preventRender"
		class="action"
		:class="{ 'is-active': isActive }"
		:style="computedStyles"
		tabindex="0"
	>
		<ZyroTooltip
			position="right"
			size="small"
			toggle-event="hover"
			content-position="absolute"
			:forced-position="{
				left: '60px',
				top: '6px',
				'white-space': 'nowrap'
			}"
			:use-portal="false"
			:hide-tooltip="isActive"
		>
			<template #trigger>
				<!-- using 'aria-label' insead of 'title', to prevent double popup on hover -->
				<Component
					:is="component"
					v-qa="`builder-sidebar-btn-${title}`"
					:icon="icon"
					:icon-dimensions="iconDimensions"
					:aria-label="title"
					class="action__button"
					tabindex="-1"
					@click.stop="$emit('action-click')"
					@toggle="$emit('action-click', $event)"
				/>
			</template>
			{{ title }}
		</ZyroTooltip>
	</div>
</template>

<script>
import BuilderHeaderSettingsPopup from '@/components/builder-view/components/BuilderHeaderSettingsPopup.vue';
import BuilderSidebarFeedbackPopup from '@/components/builder-view/components/BuilderSidebarFeedbackPopup.vue';

export default {
	components: {
		BuilderHeaderSettingsPopup,
		BuilderSidebarFeedbackPopup,
	},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		preventRender: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: 'var(--primary)',
		},
		iconDimensions: {
			type: String,
			default: null,
			validator: (iconDimensions) => iconDimensions.includes('px'),
		},
		component: {
			type: String,
			default: 'ZyroButton',
		},
	},
	computed: {
		computedStyles() {
			return { '--action-color': this.color };
		},
	},
};
</script>

<style lang="scss" scoped>
.action {
	$this: &;

	position: relative;
	margin: 4px;
	outline: none;

	&__button {
		width: 36px;
		height: 36px;
		padding: 24px;
		border-width: 0;
		border-radius: 50%;

		&:hover,
		&:focus-visible {
			color: var(--action-color);
		}
	}

	&::before {
		position: absolute;
		top: 10px;
		left: -4px;
		z-index: -1;
		width: 3px;
		height: 28px;
		content: '';
		background-color: var(--action-color);
		border-radius: 0 3px 3px 0;
		transition: transform 0.2s ease-out;
		transform: scale(0, 1);
		transform-origin: 100% 50%;
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		content: '';
		background-color: var(--action-color);
		border-radius: 50%;
		opacity: 0.2;
		transition: transform 0.3s ease-out;
		transform: scale(0);
	}

	&:hover,
	&:focus-visible {
		outline: none;

		&::after {
			transform: scale(1);
		}
	}

	&.is-active {
		#{$this}__button {
			color: var(--action-color);
		}

		&::before {
			transform: scale(1, 1);
		}

		&::after {
			transform: scale(1);
		}
	}
}
</style>
