<template>
	<Component
		:is="componentType"
		v-if="currentElement && currentElement.type"
		class="edit-controls"
		:block-id="blockId"
		:block-ref="blockRef"
		@close="close"
	/>
</template>

<script>
import {
	mapGetters,
	mapActions,
} from 'vuex';

export default {
	components: {
		EditText: () => import('./edit-text/EditText.vue'),
		EditButton: () => import('./edit-button/EditButton.vue'),
		EditGallery: () => import('./edit-gallery/EditGallery.vue'),
		EditImage: () => import('./edit-image/EditImage.vue'),
		EditMap: () => import('./edit-map/EditMap.vue'),
		EditVideo: () => import('./edit-video/EditVideo.vue'),
		EditSocialIcons: () => import('./edit-social-icons/EditSocialIcons.vue'),
		EditInstagramFeed: () => import('./edit-instagram-feed/EditInstagramFeed.vue'),
		EditForm: () => import('./edit-form/EditForm.vue'),
		BaseEditControls: () => import('./BaseEditControls.vue'),
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	editors: {
		GridTextBox: 'EditText',
		GridButton: 'EditButton',
		GridStripeButton: 'EditButton',
		GridGallery: 'EditGallery',
		GridImage: 'EditImage',
		GridMap: 'EditMap',
		GridVideo: 'EditVideo',
		GridSocialIcons: 'EditSocialIcons',
		GridInstagramFeed: 'EditInstagramFeed',
		GridForm: 'EditForm',
	},
	computed: {
		...mapGetters(['currentElement']),
		componentType() {
			return this.$options.editors[this.currentElement.type] ?? 'BaseEditControls';
		},
	},
	methods: {
		...mapActions('gui', ['setEditMode']),
		close() {
			this.setEditMode(false);
		},
	},
};
</script>
<style lang="scss" scoped>
.edit-controls {
	z-index: z-index(controls--edit);
}
</style>
