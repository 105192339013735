import {
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_SMALL,
} from '@/constants/globalStyles';
import i18n from '@/i18n/setup';

export const ENTER_KEY = 'Enter';
export const LETTERCASES = [
	'uppercase',
	'lowercase',
	'capitalize',
];

/**
 * Node, mark and plugin names
 */
export const PARAGRAPH_NODE_NAME = 'paragraph';
export const HEADING_NODE_NAME = 'heading';
export const COLOR_MARK_NAME = 'color';
export const LETTERCASE_MARK_NAME = 'letterCase';
export const LINK_MARK_NAME = 'link';
export const ENTER_PLUGIN_NAME = 'EnterHandler';

/**
 * Heading/Paragraph constants
 */
export const H1_CLASS = 'h-1';
export const H2_CLASS = 'h-2';
export const H3_CLASS = 'h-3';
export const H4_CLASS = 'h-4';
export const H5_CLASS = 'h-5';
export const H6_CLASS = 'h-6';
export const BODY_LARGE_CLASS = 'body-large';
export const BODY_CLASS = 'body';
export const BODY_SMALL_CLASS = 'body-small';

export const HEADING_TEXT_TYPES = {
	[H1_CLASS]: {
		title: `${i18n.t('common.heading')} 1`,
		typographyName: TYPOGRAPHY_H1,
	},
	[H2_CLASS]: {
		title: `${i18n.t('common.heading')} 2`,
		typographyName: TYPOGRAPHY_H2,
	},
	[H3_CLASS]: {
		title: `${i18n.t('common.heading')} 3`,
		typographyName: TYPOGRAPHY_H3,
	},
	[H4_CLASS]: {
		title: `${i18n.t('common.heading')} 4`,
		typographyName: TYPOGRAPHY_H4,
	},
	[H5_CLASS]: {
		title: `${i18n.t('common.heading')} 5`,
		typographyName: TYPOGRAPHY_H5,
	},
	[H6_CLASS]: {
		title: `${i18n.t('common.heading')} 6`,
		typographyName: TYPOGRAPHY_H6,
	},
};

export const PARAGRAPH_TEXT_TYPES = {
	[BODY_LARGE_CLASS]: {
		title: `${i18n.t('common.paragraph')} 1`,
		typographyName: TYPOGRAPHY_BODY_LARGE,
	},
	[BODY_CLASS]: {
		title: `${i18n.t('common.paragraph')} 2`,
		typographyName: TYPOGRAPHY_BODY,
	},
	[BODY_SMALL_CLASS]: {
		title: `${i18n.t('common.paragraph')} 3`,
		typographyName: TYPOGRAPHY_BODY_SMALL,
	},
};

export const TEXT_TYPES = {
	...HEADING_TEXT_TYPES,
	...PARAGRAPH_TEXT_TYPES,
};

/**
 * Tags
 */
export const H1_TAG = 'h1';
export const H2_TAG = 'h2';
export const H3_TAG = 'h3';
export const H4_TAG = 'h4';
export const H5_TAG = 'h5';
export const H6_TAG = 'h6';
export const P_TAG = 'p';
export const HEADING_TAGS = [
	H1_TAG,
	H2_TAG,
	H3_TAG,
	H4_TAG,
	H5_TAG,
	H6_TAG,
];

/**
 * Validations
 */
export const ALLOWED_STYLE_PROPERTIES_FOR_TEXT = [
	'color',
	'text-transform',
	'font-style',
	'text-decoration',
	'font-weight',
];
