<template>
	<div class="lang-switcher">
		<div
			v-for="locale in availableLocales"
			:key="locale.code"
			v-qa="`builder-header-localeslist-link-${locale.code}`"
			class="locale"
			@click="setLocale(locale.code)"
		>
			<img
				:src="require(`@/assets/images/i18n/${locale.code}.png`)"
				:alt="`${locale.code}-flag`"
			>
			<label
				class="locale__label z-body-small"
			>
				{{ locale.region }}
				<span class="locale__text">{{ locale.language }}</span>
			</label>
		</div>
	</div>
</template>

<script>
import { SUPPORTED_LOCALES } from '@/utils/i18n/supportedLocales';

export default {
	computed: {
		availableLocales() {
			return SUPPORTED_LOCALES;
		},
	},
	methods: {
		setLocale(locale) {
			if (this.$i18n.locale !== locale) {
				const route = {
					...this.$route,
					query: {
						...this.$route.query,
						lang: locale,
					},
				};
				const { href } = this.$router.resolve(route);

				window.location.replace(href);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.lang-switcher {
	width: 222px;
	padding: 10px 0;
	background: $light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
}

.locale {
	display: flex;
	align-items: center;
	padding: 8px 14px;
	color: $dark;

	&:hover {
		cursor: pointer;
		background: $grey-100;
	}

	&__label {
		padding-left: 10px;
		cursor: pointer;
	}

	&__text {
		color: $grey-600;
	}
}

</style>
